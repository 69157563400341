<!--头寸-->
<template>
<div class='positions' style="position:relative;">
    <div class="panel">
        <div class="panel-title" style="color: #B268FF;">{{ $t('mining') }}</div>
        <div class="panel-desc">
            <div>{{ $t('TradingMiningDesc') }}</div> 
            <div v-if="0">{{ $t('LiquidityMiningDesc') }}</div>
        </div>
        <div class="card-row">
            <div class="value-card">
                <div class="value-card-label">{{ $t('CurrentTermTradingFee') }}</div>
                <div class="value-card-value">{{ format(miningData.currentTerm.traderFee) }}</div>
                <div class="value-card-extra">
                    <span>{{ $t('MyShare') }}: {{format(miningData.currentTerm.traderFee*100/miningData.currentTerm.totalFee)}}%</span>
                </div>
            </div>
            <div class="value-card" v-if="0">
                <div class="value-card-label">{{ $t('LiquidityScore') }}</div>
                <div class="value-card-value">{{ format(miningData.myLiquidityScore) }}</div>
                <div class="value-card-extra">
                    <span>{{ $t('MyShare') }}: {{format(miningData.myLiquidityScore*100/miningData.totalLiquidityScore)}}%</span>
                </div>
            </div>

            <div class="value-card" style="min-width:280px">
                <div class="value-card-label">{{ $t('EstimatedReward') }}</div>
                <div class="value-card-value">{{ format(miningData.currentTerm.traderFee*miningData.currentTerm.totalMeke/miningData.currentTerm.totalFee) }}</div>
                <div class="value-card-extra" style="width:100%">
                    <span>{{ $t('CurrentProgress') }}: {{curDays}}/{{miningData.period/3600/24}}</span>
                </div>
            </div>
            
            <div class="value-card" style="min-width:280px">
                <div class="value-card-label">{{ $t('TotalReward') }}</div>
                <div class="value-card-value-row">
                    <span class="value-card-value">{{ format(miningData.totalAmount) }}</span>
                    <button
                        :disabled="miningData.totalReleased - miningData.totalAmountCollected < 0.0001"
                        class="collect-btn" 
                        @click="collect()">{{ $t('collect') }}</button>
                </div>
                <div class="value-card-extra">
                    <span>{{ $t('Received') }}: {{ format(miningData.totalAmountCollected) }}</span>
                    <span>{{ $t('Remain') }}: {{ format(miningData.totalReleased - miningData.totalAmountCollected) }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="panel my-stakes">
        <div class="panel-title">{{$t('MiningHistory')}}</div>
        <div style="padding:8px">
            <TradeTable 
            :columns="['C_StartTime','MyShare','TradingFee','TermReward'].map(t=>({title:$t(t)}))" 
            :tableData="miningData.terms" :noData="$t('MiningHistory')" @scrollBottom="scrollBottom()">
                <template v-slot:default="props">
                    <td style="white-space:nowrap;">{{utcDate(props.scope.termId)}}</td>
                    <td>{{format4(props.scope.traderFee*100/props.scope.totalFee)}}%</td>
                    <td>{{format(props.scope.traderFee)}}</td>
                    <td>{{format(props.scope.traderFee*props.scope.totalMeke/props.scope.totalFee)}}</td>
                </template>
            </TradeTable>
        </div>
    </div>

    <ConnectWallet v-model="isconnectWallet" key="1"></ConnectWallet>
    <loading-layer v-model="isLayerPop"></loading-layer>
    <loading-pop v-model="isLoadingPop" text="Loading..."></loading-pop>
</div>
</template>

<script>
import ConnectWallet from "@/components/model/connectWallet.vue"
import {getMiningDetail, getMiningSign } from "@/api/http"
import {fromWei} from "@/utils/signFn/constants"
import ContractUtil from "@/utils/contractUtil"
import WalletUtil from "@/utils/walletUtil"
import loadingPop from "@/components/loadingPop.vue"

import config from '@/config'
import miningAbi from '@/utils/build/contracts/Mining.json'

import TradeTable from "@/components/tradeTable.vue"

const formatter= new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})

const formatter4= new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4
})


const emptyData={
    currentTerm:{},
    terms:[],
}

export default {
    name: '',
    props: {

    },
    components: {
        loadingPop, ConnectWallet,TradeTable
    },
    data() {
        return {
            loadingText:'',//加载中文字
            //表格标题
            isLayerPop:true,//加载中
            isLoadingPop:false,
            miningData: emptyData,
            isconnectWallet:false,
            miningList:[],
        }
    },
    mounted() {
        this.getMiningData()
    },
    methods: {
        copy(txt) {
            this.$copyText(txt).then((e) => {
                this.$Message.success(this.$t('copySuccess'));
            }, function (e) {
            })
        },
        format(v){
            if(v===undefined || v===null || isNaN(v) || !Number.isFinite(v)){
                return '--'
            }
            return formatter.format(v)
        },
        format4(v){
            if(v===undefined || v===null || isNaN(v) || !Number.isFinite(v)){
                return '--'
            }
            return formatter4.format(v)
        },
        utcDate(timestamp, suffix=''){
            return new Date(timestamp*1000).toISOString().substring(0,10).replace('T',' ')+suffix;
        },
        async getMiningData() {
            const addr=this.addr;
            if(!addr){
                this.isLayerPop = false
                return
            }

            await getMiningDetail(addr).then(res=>{
                res.data.totalAmountCollected ||=0;
                this.miningData=res.data
            }).catch(err=>{
                this.miningData = emptyData
                console.warn(err.message)
            }).finally(()=>{
                this.isLayerPop=false
            })
            this.updateData()
        },

        getMining(needChainId){
            const chainInfo=Object.values(config.chainInfos).find(c=>parseInt(c.chainId)===needChainId);
            if(chainInfo == null){
                throw new Error('Unsupport chain');
            }
            if(!chainInfo.provider){
                chainInfo.provider = ContractUtil.createProvider(chainInfo.rpcUrls);
            }

            if(!chainInfo.miningContract){
                chainInfo.miningContract = ContractUtil.createContract(miningAbi, chainInfo.provider);
            }
            return chainInfo.miningContract;
        },

        async getMiningCollected(){
            if(!this.addr){
                return;
            }

            const record = this.miningData;
            const mining=await this.getMining(parseInt(record.chainId || this.chainId)).at(record.contract)
            const collectedAmount = await mining.collectedAmounts(this.addr);
            const collected =  Number(fromWei(collectedAmount));
            record.totalAmountCollected = collected;
        },
        async updateData(){
            this.getMiningCollected();
        },

        async collect(){
            let addr=this.addr;
            if (!this.addr) {
                this.isconnectWallet = true
                return;
            }

            var needChainId = this.miningData.chainId||this.chainId;
            if(this.chainId !== needChainId ){
                const chainInfo=Object.values(config.chainInfos).find(c=>parseInt(c.chainId)===needChainId);
                this.toggleChain(chainInfo);
                return
            }

            this.isLoadingPop = true
            try{
                const res=await getMiningSign(addr)
                const {contract, signature, rawData:{timestamp,totalReleased}={}} = res.data||{}
                if(signature && contract){
                    const mining=await WalletUtil.mining.at(contract)
                    const tx=await mining.collect(BigInt(totalReleased),timestamp,signature,{from: addr})
                    this.updateData();
                    this.$Message.success(this.$t('SuccessToCollect'))
                }
            }catch(err){
                
                let errMsg = err.message || 'Error';
                console.warn(errMsg);
                const dataStart = errMsg.indexOf('{')
                const dataEnd = errMsg.lastIndexOf('}');
                if (dataStart > 0 && dataEnd > 0) {
                    try {
                        const data = JSON.parse(errMsg.substring(dataStart, dataEnd + 1));
                        errMsg = data.message || errMsg;
                    } catch (e) {
                        //ignore;
                    }
                }
                if (errMsg.toLowerCase().includes('signature')) {
                    errMsg = this.$t("InvalidSignature");
                } else if (errMsg.includes('Insufficient balance')) {
                    errMsg = this.$t("MiningInsufficient");
                }
                this.$Message.error(this.$t('operationFailed') + '<br/>' + errMsg);
            }
            this.isLoadingPop = false
        },
         //切换链
        async toggleChain(chainInfo) {
            const chain = chainInfo || this.$config.chainInfo
            try {
                await WalletUtil.provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chain.chainId }]
                });
                WalletUtil.walletInit(() => { })
            } catch (switchError) {
                console.warn(switchError)
                try {
                    await WalletUtil.provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [chain]
                    });
                    await WalletUtil.provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: chain.chainId }]
                    });
                    WalletUtil.walletInit(() => { })
                } catch (addError) {
                    console.warn(addError)
                }
            }
        }
    },
    computed: {
        curDays(){
            return  Math.floor((Date.now()/1000 - this.miningData.currentTerm.termId)/(3600*24))+1;
        },
        progress(){
            return  (Date.now()/1000 - this.miningData.currentTerm.termId)*100/this.miningData.period;
        },
        //钱包地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
        //多语言切换
        lang(){
            return this.$i18n.locale;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
        chainId() {
            return this.$store.state.app.wallet.chainId;
        },
    },
    watch: {
        //监听多语言切换
        lang:function(){
        },
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    this.getMiningData()
                }else{
                    this.miningData= emptyData
                }
            },
            deep: false,
            immediate: true
        }
    },
};
</script>
<style lang='less' scoped>
.positions{
    flex:1;
    display:flex; 
    flex-direction: column; 
    overflow:auto;
    padding:24px;
    gap:48px;
}

.panel {
    display:flex;
    flex-direction:column;
    gap:24px;
}

.panel-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #F5F5F5;
}
.panel-desc {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6F6E82;
}

.card-row {
    display:flex;
    gap:12px;
}

.value-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 24px 32px;
    gap: 12px;

    width: 264px;
    height: 127px;

    border: 1px solid #333333;
    border-radius: 4px;
}

.value-card-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6F6E82;
}

.value-card-value-row {
    gap:24px;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.value-card-value {
    font-weight: 600;
    font-size: 28px;
    line-height: 39px;
    text-align: center;

    color: #F5F5F5;
}

.collect-btn {
    height:28px;
    font-size:14px;
    line-height:18px;
    padding:4px 8px;
    border:1px solid #ddd;
    border-radius:4px;
    background:transparent;
    color:white;
    white-space:nowrap;
}

.collect-btn:disabled {
    opacity:0.65;
    cursor:default;
}
.value-card-extra {
    gap:24px;
    display:flex;
    justify-content: space-between; 
    opacity:0.8;
    font-size:12px;
    margin-top:-8px;
    & > span {
        white-space: nowrap;
    }
}
</style>
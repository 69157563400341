<!--头寸-->
<template>
<div class='positions' style="position:relative;">
    <div class="positionsTitle">
        <span>{{this.$t("openPositions")}}</span>
    </div>
    <trade-table :columns="tableTitleList" :tableData="positionDataList" :noData="$t('position2')+$t('fullStop')" :key="`position`">
        <template v-slot:default="props">
            <td>{{props.scope.contract}}-USD</td>
            <td>
                <span v-if="props.scope.side==2" class="red">{{$t('_sell')}}</span>
                <span v-if="props.scope.side==3" class="green">{{$t('_buy')}}</span>
            </td>
            <td>{{props.scope.size | toFixed(3)}}</td>
            <td>{{props.scope.marginBalance | numFormat(2)}}</td>
            <td>{{props.scope.bondRate*100 | toFixed(2)}}%</td>
            <td>{{props.scope.positionAvgPrice | numFormat(2)}}</td>
            <td>{{props.scope.signPrice | numFormat(2)}}</td>
            <td>
                <span v-if="props.scope.strongPrice>=0">{{props.scope.strongPrice | numFormat(2)}}</span>
                <span v-else>0</span><!--{{$t('neverClose')}}-->
            </td>
            <!-- <td>{{props.scope.fundRate | toFixed(2)}}%</td> -->
            <td>{{props.scope.profitLoss | numFormat(2)}}</td>
            <td style="position:relative;">
                <Dropdown>
                    <a href="javascript:void(0)" style="display:block;text-align:center;"><Icon type="ios-more" size="32"/></a>
                    <template #list>
                        <DropdownMenu>
                            <DropdownItem><div class="blue printer active_t" @click="e => closePosition(props.scope)">{{ $t('closePosition') }}</div></DropdownItem>
                            <DropdownItem><div class="blue printer active_t" @click="e => showStopProfit(props.scope)">{{ $t('stopProfitSetting') }}</div></DropdownItem>
                            <DropdownItem><div class="blue printer active_t" @click="e => showStopLoss(props.scope)">{{ $t('stopLossSetting') }}</div></DropdownItem>
                        </DropdownMenu>
                    </template>
                </Dropdown>
            </td>
        </template>
    </trade-table>
    <loading-layer v-model="isLayerPop"></loading-layer>
    <loading-pop v-model="isLoadingPop" :text="loadingText"></loading-pop>

        <Modal v-model="sp.show" width="400px" :mask-closable="false">
            <template slot="header">
            <Icon type="information-circled"></Icon>
            <span>{{ $t('stopProfitSetting') }}</span>
            </template>
            <div class="form-v">
                <div class="position-info">
                    <div><span class="field-label">{{ $t('Size') }}</span><span class="field-value">{{ numberFormat(sp.p.size) }} ETH</span></div>
                    <div><span class="field-label">{{ $t('Side') }}</span><span class="field-value">{{ formatSide(sp.p.side) }}</span></div>
                    <div><span class="field-label">{{ $t('CostPrice') }}</span><span class="field-value">{{ usdFormat(sp.p.positionAvgPrice) }}</span></div>
                    <div><span class="field-label">{{ $t('profitLoss') }}</span><span class="field-value">{{ usdFormat(sp.p.profitLoss) }}</span></div>
                    <div><span class="field-label">{{ $t('signPrice') }}</span><span class="field-value">{{ usdFormat(sp.p.signPrice) }}</span></div>
                    <div><span class="field-label">{{ $t('Change') }}</span><span class="field-value">{{ numberFormat(calcProfitLossChange(sp.p, sp.p.signPrice)) }}%</span></div>
                </div>

                <div class="form-item-v">
                    <label class="form-item-label">{{ $t('StopProfitPrice') }}</label>

                    <InputNumber
                    style="display:block;width:100%"
                    :min="sp.min"
                    :max="sp.max"
                    :step="sp.step"
                    :precision="blockchain.priceAccuracy"
                    v-model="sp.price"
                    :formatter="v => '$' + v"
                    :parser="v => v.replaceAll('$', '').replaceAll(',', '').replaceAll(' ', '')"/>

                    <div class="flex-row">
                        <div><span class="field-label">{{ $t('Change') }}</span><span class="field-value">{{ numberFormat(calcProfitLossChange(sp.p, sp.price)) }}%</span></div>
                        <div><span class="field-label">{{ $t('profitLoss') }}</span><span class="field-value">≈ {{ usdFormat(calcProfitLoss(sp.p, sp.price)) }}</span></div>
                    </div>
                </div>

                <div class="form-item-v">
                    <label class="form-item-label">{{ $t('CloseOrderType') }}:</label>
                    <Select v-model="sp.type">
                        <Option value="limit">{{ $t('LimitOrder') }}</Option>
                            <Option value="market">{{ $t('MarketOrder') }}</Option>
                    </Select>
                </div>
    
                <div class="form-item-v">
                    <label class="form-item-label">{{ $t('Amount') }}(ETH):</label>
                    <InputNumber
                        style="display:block;width:100%"
                        :step="0.1"
                        :precision="blockchain.amountAccuracy"
                        :min="Math.pow(10, -blockchain.amountAccuracy)"
                        v-model="sp.amount"
                        />
                    <div class="flex-row">
                        <span class="percent-btn" @click="e => setAmount(sp, sp.p.size * 0.25)">25%</span>
                        <span class="percent-btn" @click="e => setAmount(sp, sp.p.size * 0.5)">50%</span>
                        <span class="percent-btn" @click="e => setAmount(sp, sp.p.size * 0.75)">75%</span>
                        <span class="percent-btn" @click="e => setAmount(sp, sp.p.size)">100%</span>
                    </div>
                </div>
            </div>

            <template slot="footer">
            <Button type="info" size="large" long @click="setStopProfit">
                <span>{{ $t('stopProfitSetting') }}</span>
            </Button>
            </template>
        </Modal>
         <Modal v-model="sl.show" width="400px" :mask-closable="false">
            <template slot="header">
            <Icon type="information-circled"></Icon>
            <span>{{ $t('stopLossSetting') }}</span>
            </template>
            <div class="form-v">
                <div class="position-info">
                    <div><span class="field-label">{{ $t('Size') }}</span><span class="field-value">{{ numberFormat(sl.p.size) }} ETH</span></div>
                    <div><span class="field-label">{{ $t('Side') }}</span><span class="field-value">{{ formatSide(sl.p.side) }}</span></div>
                    <div><span class="field-label">{{ $t('CostPrice') }}</span><span class="field-value">{{ usdFormat(sl.p.positionAvgPrice) }}</span></div>
                    <div><span class="field-label">{{ $t('profitLoss') }}</span><span class="field-value">{{ usdFormat(sl.p.profitLoss) }}</span></div>
                    <div><span class="field-label">{{ $t('signPrice') }}</span><span class="field-value">{{ usdFormat(sl.p.signPrice) }}</span></div>
                    <div><span class="field-label">{{ $t('Change') }}</span><span class="field-value">{{ numberFormat(calcProfitLossChange(sl.p, sl.p.signPrice)) }}%</span></div>
                </div>

                <div class="form-item-v">
                    <label class="form-item-label">{{ $t('StopLossPrice') }}</label>

                    <InputNumber
                    style="display:block;width:100%"
                    :min="sl.min"
                    :max="sl.max"
                    :step="sl.step"
                    :precision="blockchain.priceAccuracy"
                    v-model="sl.price"
                    :formatter="v => '$' + v"
                    :parser="v => v.replaceAll('$', '').replaceAll(',', '').replaceAll(' ', '')"/>

                    <div class="flex-row">
                        <div><span class="field-label">{{ $t('Change') }}</span><span class="field-value">{{ numberFormat(calcProfitLossChange(sl.p, sl.price)) }}%</span></div>
                        <div><span class="field-label">{{ $t('profitLoss') }}</span><span class="field-value">≈ {{ usdFormat(calcProfitLoss(sl.p, sl.price)) }}</span></div>
                    </div>
                </div>

                <div class="form-item-v">
                    <label class="form-item-label">{{ $t('CloseOrderType') }}:</label>
                    <Select v-model="sl.type">
                        <Option value="limit">{{ $t('LimitOrder') }}</Option>
                        <Option value="market">{{ $t('MarketOrder') }}</Option>
                    </Select>
                </div>

                <div class="form-item-v">
                    <label class="form-item-label">{{ $t('Amount') }}(ETH):</label>
                    <InputNumber
                        style="display:block;width:100%"
                        :step="0.1"
                        :precision="blockchain.amountAccuracy"
                        :min="Math.pow(10, -blockchain.amountAccuracy)"
                        v-model="sl.amount"
                        />
                    <div class="flex-row">
                        <span class="percent-btn" @click="e => setAmount(sl, sl.p.size * 0.25)">25%</span>
                        <span class="percent-btn" @click="e => setAmount(sl, sl.p.size * 0.5)">50%</span>
                        <span class="percent-btn" @click="e => setAmount(sl, sl.p.size * 0.75)">75%</span>
                        <span class="percent-btn" @click="e => setAmount(sl, sl.p.size)">100%</span>
                    </div>
                </div>
            </div>

            <template slot="footer">
            <Button type="info" size="large" long @click="setStopLoss">
                <span>{{ $t('stopLossSetting') }}</span>
            </Button>
            </template>
        </Modal>
</div>
</template>

<script>
import {getTableTitleList} from '../const/const.js'
import { subOrder,getOrder,getAllOrder,cancelOrder,sendMsg,userFee } from "@/api/http"
import config from "@/config";
import { buildOrder,signTypedDataV4 } from "@/utils/signFn/order";//签名函数，生成订单
import tradeTable from "@/components/tradeTable.vue"
import ContractUtil from "@/utils/contractUtil"
import loadingPop from "@/components/loadingPop.vue"

const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})

const usdFormatter = new Intl.NumberFormat("en-US", {
    style: 'currency',
    currency: 'USD'
})

let loading=false;
let getNowId=null;
export default {
    name: '',
    props: {

    },
    components: {
        tradeTable,loadingPop
    },
    data() {
        return {
            sp: { //止盈
                p: {},
                step: 0.1,
                show: false,
                type: 'limit',
                price: 0,
                amount: 0,
            },
            sl: { //止损
                p: {},
                step: 0.1,
                show: false,
                type: 'limit',
                price: 0,
                amount: 0,
            },
            isLoadingPop:false,//加载中弹窗
            loadingText:'',//加载中文字
            //表格标题
            tableTitleList:getTableTitleList(),
            positionDataList:[],//当前持仓数据
            isLayerPop: false,//加载中
            dataList:[],//交易对列表
            addrs:[],//交易对数组
        }
    },
    mounted() {
        this.initSocket();
    },
    methods: {
        setAmount(cp, amount) {
            cp.amount = Number(amount.toFixed(this.blockchain.amountAccuracy));
        },
        setPrice(cp, price) {
            cp.price = Number(price.toFixed(this.blockchain.priceAccuracy));
        },
        formatSide(side) {
            switch (parseInt(side)) {
                case 3: return this.$t('LONG')
                case 2: return this.$t('SHORT')
                case 1: return this.$t('EMPTY')
                case 0: return this.$t('FLAT')
            }
            return '--'
        },
        numberFormat(v) {
            if (v == null) {
                return '--'
            }
            return formatter.format(v);
        },
        usdFormat(v) {
            if (v == null) {
                return '--'
            }
            return usdFormatter.format(v);
        },
        calcProfitLoss(p, price) {
            if (p.side == 0 || p.side == 1) {
                return 0;
            }

            if (p.side == 3) {
                return (price - p.positionAvgPrice) * p.size + p.otherProfitLoss
            } else if (p.side == 2) {
                return (p.positionAvgPrice - price) * p.size + p.otherProfitLoss
            }
            return 0;
        },
        calcProfitLossChange(p, price) {
            if (p.side == 0 || p.side == 1) {
                return 0;
            }

            if (p.side == 3) {
                return (price - p.positionAvgPrice) * 100 / p.positionAvgPrice
            } else if (p.side == 2) {
                return (p.positionAvgPrice - price) * 100 / p.positionAvgPrice
            }
            return 0;
        },
        showStopProfit(p) {
            //enum Side {FLAT, EMPTY, SHORT, LONG}
            if (p.side == 0 || p.side == 1) {
                return;
            }

            const step = Math.round(p.positionAvgPrice * 2) / 1000;
            const d = {
                p,
                step: Number(step.toFixed(this.blockchain.priceAccuracy)),
                amount: p.size,
                price: p.signPrice,
                type: 'limit',
                show: true
            }

            if (p.side == 3) {
                d.min = p.signPrice;
            } else if (p.side == 2) {
                d.max = p.signPrice;
            }
            this.sp = d;
        },
        showStopLoss(p) {
            //enum Side {FLAT, EMPTY, SHORT, LONG}
            if (p.side == 0 || p.side == 1) {
                return;
            }
            const step = Math.round(p.positionAvgPrice * 2) / 1000;
            const d = {
                p,
                step: Number(step.toFixed(this.blockchain.priceAccuracy)),
                amount: p.size,
                price: p.signPrice,
                type: 'limit',
                show: true
            }

            if (p.side == 3) {
                d.max = p.signPrice;
            } else if (p.side == 2) {
                d.min = p.signPrice;
            }
            this.sl = d;
        },

        //平仓
        async closePosition(p) {
            //enum Side {FLAT, EMPTY, SHORT, LONG}
            if (p.side == 0 || p.side == 1) {
                return;
            }

            const order = {
                type: 'market',
                price: 0,
                amount: p.size,
                side: p.side == 3 ? 'sell' : 'buy',
                close: 1,
                perpetual: p.perpetual,
                broker: p.broker,
            }
            return this.createOrder(order)
        },

        //设置止盈
        async setStopProfit() {
            this.setPrice(this.sp, this.sp.price);
            this.setAmount(this.sp, this.sp.amount);
            const { type, price, amount, p } = this.sp;
            //enum Side {FLAT, EMPTY, SHORT, LONG}
            if (p.side == 0 || p.side == 1) {
                return;
            }

            const order = {
                type,
                price: type == 'market' ? 0 : price,
                amount,
                side: p.side == 3 ? 'sell' : 'buy',
                keepMinPrice: p.side == 3 ? 0 : price,
                keepMaxPrice: p.side == 3 ? price : 0,
                close: 0,
                perpetual: p.perpetual,
                broker: p.broker,
            }
            await this.createOrder(order)
            this.sp.show = false;
        },

        //设置止损
        async setStopLoss() {
            this.setPrice(this.sl, this.sl.price);
            this.setAmount(this.sl, this.sl.amount);
            const { type, price, amount, p } = this.sl;
            //enum Side {FLAT, EMPTY, SHORT, LONG}
            if (p.side == 0 || p.side == 1) {
                return;
            }
            const order = {
                type,
                price: type == 'market' ? 0 : price,
                amount,
                side: p.side == 3 ? 'sell' : 'buy',
                keepMinPrice: p.side == 3 ? price : 0,
                keepMaxPrice: p.side == 3 ? 0 : price,
                close: 0,
                perpetual: p.perpetual,
                broker: p.broker,
            }
            await this.createOrder(order)
            this.sl.show = false;
        },

        async createOrder(order) {
            if (loading) {
                this.$Message.success(this.$t('loading'));
                return false;
            }

            if(!this.addr){
                return;
            }

            const addr = this.addr;
            let resUserFee = await userFee(this.addr);
            if (resUserFee.code != 200) { this.$Message.error(this.$t('operationFailed')); return; }
            loading = true;
            const unsignedData = {
                trader: addr,//用户地址
                version: 2,//版本
                makerFeeRate: resUserFee.data.makerFeeRate,
                takerFeeRate: resUserFee.data.takerFeeRate,
                leverage: 25,
                close: 1,
                ...order,
            }
            var res = await signTypedDataV4(unsignedData, order.perpetual, order.broker, addr).then(async data => {
                loading = false;
                data.signature = JSON.stringify(data.signature);
                data.type = unsignedData.type;     //类型。market：市价，limit：限价
                data.side = unsignedData.side;     //买和卖
                data.leverage = unsignedData.leverage;
                data.close = unsignedData.close;//平仓
                if (order.keepMinPrice > 0) {
                    data.keepMinPrice = order.keepMinPrice;
                }

                if (order.keepMaxPrice > 0) {
                    data.keepMaxPrice = order.keepMaxPrice;
                }

                // data.makerFee= tradeFee;//资金费率
                // data.takerFee= tradeFee;//资金费率
                console.log("签名成功：", data)
                this.isLoadingPop = true;
                this.loadingText = this.$t('closingPosition');
                await subOrder(data)
                    .then(res => {
                        this.isLoadingPop = false;
                        if (res.code == 200) {
                            this.$Message.success(this.$t('operationSucceeded'));
                            this.$eventBus.$emit("update_current_position");//更新当前持仓
                            this.$eventBus.$emit("changeBalance");//余额变动
                        } else if (res.code == 600) {      //未匹配成功
                            this.$Message.error(this.$t('insufficientLiquidity'));
                        } else if (res.code == 601) {      //未匹配到订单
                            this.$Message.error(this.$t('orderNotMatched'));
                        } else if (res.code == 602) {      //您未有内测资格
                            this.$Message.error(this.$t('youNotTest'));
                        } else {
                            // this.$Message.error(this.$t('operationFailed'));
                            this.$Message.error(res.msg);
                        }
                    })
                    .catch(err => {
                        console.warn(err);
                    });
            }).catch(err => {
                loading = false;
                console.warn(err);
            });
        },

        //设置监听钱包
        setEventListener(){
            /**获取当前持仓 */
            // const equipment = new ContractUtil.web3.eth.Contract(ContractUtil.perpetual.abi,this.blockchain.perpetual);
            // const equipment2 =  new ContractUtil.web3.eth.Contract(ContractUtil.amm.abi,this.blockchain.fundmork);
            // const exchange =  new ContractUtil.web3.eth.Contract(ContractUtil.exchange.abi,this.blockchain.exchange);
            // // equipment.getPastEvents('Deposit', {filter: {}, fromBlock: 0,toBlock: 'latest'}, function(error, events){  });
            // let addr=this.$store.state.app.wallet.address;
            // //取款
            // equipment.events.Withdraw({filter:{trader:addr}},(error,events)=>{
            //     this.getInfo();
            // })
            // //存款
            // equipment.events.Deposit({filter:{trader:addr}},(error,events)=>{
            //     this.getInfo();
            // })
            // //清算
            // equipment.events.Liquidate({filter:{trader:addr}},(error,events)=>{
            //     this.getInfo();
            // })
            // //
            // exchange.events.MatchWithOrders({filter:{perpetual:this.blockchain.perpetual}},(error,events)=>{
            //     this.getInfo();
            // })
        },
        //获取头寸列表
        async getInfo(){
            let contractReader = await ContractUtil.contractReader.at(this.blockchain.contractReader);
            await contractReader.getTraderAllPosition.call(this.addrs,this.addr).then(res=>{
                this.isLayerPop=false;
                // console.log(res,'getTraderAllPosition');
                let data=res || [];
                this.addrs.forEach((item,index)=>{
                    this.updateInfo(this.dataList[index],data[index],index);
                });
                //考虑到定时器取消后，当前持仓已经开始获取了
                if(!this.isInitWallet){
                    this.positionDataList=[];
                }
            })
        },
        //item:后端数据的每一项,res：区块链数据的每一项,index：下标
        updateInfo(item,res,index){
            if(res[4].side!=2 && res[4].side!=3){    //没有持仓
                // this.positionDataList=[];
                return;
            }
            let p=Number(1+('0').repeat(18));
            let size=Number(res.marginAccount.size)/p,
                cashBalance=Number(res.marginAccount.cashBalance)/p,
                entryValue=Number(res.marginAccount.entryValue)/p;
            let strongPrice=0,
                profitLoss=0,
                otherProfitLoss =0,
                marginBalance=Number(res.marginBalance)/p,
                markPrice=Number(res.markPrice)/p,
                // accumulatedFundingPerContract=Number(res[0].perpetualStorage[11].fundingParams.accumulatedFundingPerContract)/p,
                accumulatedFundingPerContract=Number(res[3][10].accumulatedFundingPerContract)/p,
                maintenanceMargin=Number(res.maintenanceMargin)/p,
                entryFundingLoss=Number(res.marginAccount.entryFundingLoss)/p,
                entrySocialLoss=Number(res.marginAccount.entrySocialLoss)/p,
                longFundingLoss=Number(accumulatedFundingPerContract*size-entryFundingLoss),
                shortSocialLossPerContract=Number(res[3].shortSocialLossPerContract)/p,
                longSocialLossPerContract=Number(res[3].longSocialLossPerContract)/p,
                shortSocialLoss = Number(shortSocialLossPerContract*size-entrySocialLoss),
                longSocialLoss = Number(longSocialLossPerContract*size-entrySocialLoss);
            if(res[4].side==2){    //做空
                // console.log(marginBalance,entryValue,size,88)
                strongPrice=((cashBalance+entryValue+longFundingLoss-shortSocialLoss)/(size*0.03+size)).toFixed(2);
                profitLoss=entryValue-markPrice*size-shortSocialLoss+longFundingLoss;
                otherProfitLoss = -shortSocialLoss + longFundingLoss;
            }else if(res[4].side==3){      //做多
                strongPrice=((cashBalance-entryValue-longFundingLoss-longSocialLoss)/(size*0.03-size)).toFixed(2);
                profitLoss=markPrice*size-entryValue-longSocialLoss-longFundingLoss;
                otherProfitLoss = -longSocialLoss - longFundingLoss;
            }
            let obj={
                abbreviation:item.abbreviation,
                contract:item.abbreviation,//合约
                side:res[4].side,//方向
                size:size,//大小
                marginBalance:marginBalance,//保证金
                bondRate:maintenanceMargin/marginBalance,
                // bondRate:marginBalance != 0 ? (cashBalance*0.03)/marginBalance : 0,//保证金率
                positionAvgPrice:size != 0 ? entryValue/size : 0,//持仓均价
                signPrice:markPrice,//标记价格
                strongPrice:strongPrice,//强平价格
                profitLoss:profitLoss,//盈亏
                otherProfitLoss,
                ...item
            }
            this.$set(this.positionDataList,index,obj);
            // console.log("当前持仓：",obj);
        },
        //连接socket
        initSocket(){
            //盘口信息
            this.$eventBus.$on("position_msg",(res)=>{
                clearInterval(getNowId);
                let list=res.data || [];
                this.dataList=list;
                let addrs=[];
                this.dataList.forEach(item=>{
                    addrs.push(item.perpetual);
                });
                this.positionDataList=[];
                this.addrs=addrs;
                this.getInfo();
                getNowId=setInterval(()=>{
                    this.getInfo();
                },5000);
            });
        },
        //账单
        bill(){

        },
    },
    computed: {
        //钱包地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
        //多语言切换
        lang(){
            return this.$i18n.locale;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
    },
    watch: {
        //监听多语言切换
        lang:function(){
            this.tableTitleList=getTableTitleList();
        },
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    this.isLayerPop=true;
                    setTimeout(()=>{
                        sendMsg({cmd:'position'});
                    },0)
                    this.setEventListener();
                }else{
                    this.isLayerPop=false;
                    clearInterval(getNowId);
                    this.positionDataList=[];
                }
            },
            deep: false,
            immediate: true
        }
    },
    beforeDestroy() {
        this.$eventBus.$off("position_msg");
        clearInterval(getNowId);
    },
};
</script>
<style lang='less' scoped>
.positions{flex:1;display:flex; flex-direction: column; overflow:auto;
.positionsTitle{.f24;.p20;font-weight: 700;border-bottom: 1px solid @borderColor;}
}


.position-info {
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:4px;
    padding:8px;
    background:#181818;
    & > .flex-row > * {
        width: 50%;
    }
}

.form-item-v {
    display:flex;
    flex-direction: column;
    gap:4px;
}
.form-v {
    display:flex;
    flex-direction: column;
    gap:16px;
}

.field-label {
    font-size:0.9em;
    margin-right:8px;
    &:after {
        content:':';
    }
}
.field-value {
    font-size:0.9em;
    color:#888;
}

.percent-btn {
    display:inline-block;
    padding:4px 16px;
    border-radius: 4px;
    background:#1f1f1f;
    cursor:pointer;
}

.flex-grid {
    display:grid;
    gap:4px;
}

.flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

</style>
<!--资产组合-订单-->
<template>
<div class='orders' style="position:relative;">
    <div class="ordersTitle">
        <span>{{this.$t("orders")}}</span>
    </div>
    <TradeTable :columns="ordersTitleList" :tableData="ordersDataList" :noData="$t('orders')+$t('fullStop')" :key="`traderOrder`" @scrollBottom="scrollBottom()">
        <template v-slot:default="props">
            <td>
                <img :src="blockchain.img">
                <div class="POMCSCitem">
                    <div><span>{{blockchain.fullName}}</span></div>
                    <div class="POMCSCitemContent">{{blockchain.abbreviation}}-USD</div>
                </div>
            </td>
            <td>
                <span v-if="props.scope.side=='LONG'" class="green">{{$t('buy')}}</span>
                <span v-else-if="props.scope.side=='SHORT'" class="red">{{$t('sell')}}</span>
            </td>
            <td>
                {{props.scope.matchedAmountPPB/1E9 | toFixed(blockchain.amountAccuracy)}}/{{props.scope.amountPPB/1E9 | toFixed(blockchain.amountAccuracy)}}
            </td>
            <td>{{props.scope.pricePPB/1E9 | numFormat(blockchain.amountAccuracy)}}</td>
            <!-- <td>{{props.scope.triggerPrice | toFixed(3)}}</td> -->
            <td style="flex-direction:column;align-items:flex-start;">
                <div>{{props.scope.expireStr.substr(0,10)}}</div>
                <div style="opacity: 0.5;">{{props.scope.expireStr.substr(11,8)}}</div>
            </td>
            <td>
                <span class="blue printer active_t" @click="cancelOrder(props.scope.hash)">{{$t('cannel')}}</span>
            </td>
        </template>
    </TradeTable>
    <loading-layer v-model="isLoadingLayer"></loading-layer>
</div>
</template>
<script>
import TradeTable from "@/components/tradeTable.vue"
import {getOrdersTitleList} from '../const/const.js'
import config from "@/config";
import { getOrder,cancelOrder } from "@/api/http"
export default {
    name: '',
    props: {

    },
    components: {
            TradeTable,
    },
    data() {
        return {
            isLoadingLayer:true,
            ordersTitleList:getOrdersTitleList(),//标题
            ordersDataList:[],//订单数据
        }
    },
    mounted() {

    },
    methods: {
        scrollBottom(){

        },
        //获取订单（表格）
        getOrder(){
            if(!this.blockchain.perpetual || !this.addr){
                return;
            }
            getOrder(this.addr,this.blockchain.perpetual).then(res=>{
                this.isLoadingLayer=false;
                if(res.code == 200){
                    let list=res.data || [];
                    this.ordersDataList=list;
                }else{
                    this.$Message.error(this.$t('operationFailed'));
                }
            })
            .catch(err=>{
                console.warn(err);
            });
        },
        //取消订单 side：买、卖
        cancelOrder(orderHash){
            this.$Modal.confirm({
                title: `${this.$t('cancelOrderConfirm')}`,
                content: '',
                okText:`${this.$t('confirm')}`,
                cancelText:`${this.$t('cancel')}`,
                onOk: () => {
                    if(!this.addr){
                        return;
                    }
                    cancelOrder(this.addr, {
                        orderHash
                    }).then(res=>{
                        if(res.code == 200){
                            this.$Message.success(this.$t('operationSucceeded'));
                            this.getOrder();
                        }else{
                            this.$Message.error(this.$t('operationFailed'));
                        }
                    })
                    .catch(err=>{
                        console.warn(err);
                    });
                },
                onCancel: () => {
                }
            });
            
        },
        //刷新表格表头
        initTable(){
            this.ordersTitleList=getOrdersTitleList();
        }
    },
    computed: {
         //地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
        //多语言切换
        lang(){
            return this.$i18n.locale;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
    },
    watch: {
        //监听多语言切换
        lang:function(){
            this.initTable();
        },
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    this.isLoadingLayer=true;
                    this.getOrder();
                }else{
                    this.isLoadingLayer=false;
                    this.ordersDataList=[];
                }
            },
            deep: false,
            immediate: true
        }
    },
    beforeDestroy() {

    },
};
</script>
<style lang='less' scoped>
.orders{
    flex:1;display:flex; flex-direction: column; overflow:auto;
    .ordersTitle{.f24;.p20;font-weight: 700;border-bottom: 1px solid @borderColor;}
}
</style>
<!--资产组合-概述-->
<template>
<div class="portfolioOverview">
    <!--资产组合，价值-->
    <div class="POtop" style="display:none;">
        <div class="POtopLeft">
            <!-- <div class="POLtop">
                <div class="POLtopTitle">
                    <span>{{this.$t("portfolio")}}&nbsp;{{this.$t("value")}}</span>
                </div>
                <div class="POLtopMain">
                    <span>$0.00</span>
                    <div class="POLTMcontent">
                        <span>
                        <Icon type="md-arrow-dropup" />
                        <Icon type="md-arrow-dropdown" />
                        $0.00 (0.00%)</span><span>{{this.$t("pastWeek")}}</span>
                    </div>
                </div>
            </div> -->
            <div class="POLmain">
                <div class="POLmainQuarter">
                    <div class="POLMQtitle"><span>{{this.$t("margin")}}&nbsp;{{this.$t("usage")}}</span></div>
                    <div>{{marginBalanceUse | numFormat(2)}}</div>
                </div>
                <div class="POLmainQuarter">
                    <div class="POLMQtitle"><span>{{this.$t("freeCollateral")}}</span></div>
                    <div>{{marginBalance | numFormat(2)}}</div>
                </div>
                <div class="POLmainQuarter">
                    <div class="POLMQtitle"><span>{{this.$t("leverage")}}</span></div>
                    <div>{{maxLevel}}×</div>
                </div>
                <div class="POLmainQuarter">
                    <div class="POLMQtitle"><span>{{this.$t("buyPower")}}</span></div>
                    <div>{{marginBalance?marginBalance*maxLevel:'' | numFormat(2)}}</div>
                </div>
            </div>
        </div>
        <!--连接钱包-->
        <div v-if="!isInitWallet" class="POtopRight"> 
            <div v-if="!isInitWallet" class="POTRconnect">
                <div>{{this.$t("connectWalletIntroduce")}}</div>
                <div class="mt10"><Button type="info" @click="isconnectWallet=true">{{this.$t("connectWallet")}}</Button></div>
            </div>
        </div>
        <div v-else-if="shouldSwitchChain" class="POtopRight"> 
            <div v-if="shouldSwitchChain" class="POTRconnect">
                <div>{{this.$t("toggleChainWarn")}} {{$config.chainInfo.chainName}}</div>
                <div class="mt10"><Button type="info" @click="toggleChain()">{{this.$t("switchingChain")}}</Button></div>
            </div>
        </div>
    </div>
    <!--市场-->
    <div class="POMain">
        <div class="POMainTitle">
            <span>{{this.$t("marketName")}}</span>
        </div>
        <div class="POMainContent">
            <trade-table :columns="tableTitle" :tableData="dataList" :noData="$t('marketName')+$t('fullStop')" :key="'overview'" @scrollBottom="scrollBottom('overview')">
                <template v-slot:default="props">
                    <td class="flex1 printer" @click="select(props.scope)">
                        <img :src="props.scope.img">
                        <div>
                            <div><span>{{props.scope.fullName}}</span></div>
                            <div class="POMCSCitemContent">{{props.scope.abbreviation}}-USD</div>
                        </div>
                    </td>
                    <td class="flex1"><!--指数价格-->
                        <div>
                            <div><span>{{props.scope.oraclePrice | numFormat(2)}}</span></div>
                        </div>
                    </td>
                    <td class="flex1">
                        <div v-if="props.scope.spread">
                            <span :class="{'red':props.scope.change==0,'green':props.scope.change==1}">
                                <Icon type="md-arrow-dropup" v-if="props.scope.change==1" />
                                <Icon type="md-arrow-dropdown" v-else-if="props.scope.change==0" />
                                {{props.scope.percentage | toFixed(2)}}%
                            </span>
                            <div class="POMCSCitemContent">{{props.scope.spread | numFormat(2)}}</div>
                        </div>
                        <div v-else>-</div>
                    </td>
                    <td class="flex1">
                        <div class="green">
                            <span v-if="props.scope.fundingRate==0 && props.scope.fundingRate!=''">0%</span>
                            <span v-else>{{props.scope.fundingRate | format(6,'%')}}</span>
                        </div>
                    </td>
                    <td class="flex1"><!--未平仓合约-->
                        <div>
                            <div v-if="props.scope.totalSize || props.scope.totalSize===0">
                                <span>{{props.scope.totalSize | format(2)}}<span class="tag f12">{{props.scope.abbreviation}}</span></span>
                            </div>
                            <div v-else>-</div>
                        </div>
                    </td>
                    <td class="flex1"><div><span>{{props.scope.totalVolume.toFixed(2)}} {{props.scope.abbreviation}}</span></div></td>
                    <td class="flex1"><div><span>{{props.scope.totalAmount | numFormat(2)}}</span></div></td>
                </template>
            </trade-table>
        </div>
    </div>
    <!--连接钱包弹窗-->
    <ConnectWallet v-model="isconnectWallet" key="1"></ConnectWallet>
</div>
</template>

<script>
import ConnectWallet from "@/components/model/connectWallet.vue"
import tradeTable from "@/components/tradeTable.vue"
import { getOverviewTitleList } from '../const/const.js'
import config from "@/config";
import { sendMsg } from "@/api/http"
import ContractUtil from "@/utils/contractUtil"
import WalletUtil from "@/utils/walletUtil"

let timeId=null;
let timeIdInfo=null;
export default {
    name: '',
    props: {

    },
    components: {
        ConnectWallet,tradeTable
    },
    data() {
        return {
            tableTitle:getOverviewTitleList(),
            dataList: [],//市场列表
            addrs:[],//交易对数组，
            isconnectWallet:false,//连接钱包modal
            marginBalance:'',//保证金余额
            availableMargin:'',//可用保证金
            maxLevel:25,//最大杠杆
        }
    },
    mounted() {
        setTimeout(() => {
            this.initSocket();
        }, 0);
        this.getNowData();
    },
    methods: {
        //选择交易对
        select(item){
            sessionStorage.setItem("currencyActive",item.perpetual);
            this.$router.push("/trade");
        },
        //切换链
        async toggleChain(){
            try {
                await WalletUtil.provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: this.$config.chainId }]
                });
                try {
                    WalletUtil.walletInit(() => { })
                } catch (err) { }
            } catch (switchError) {
                console.warn(switchError)
                try {
                    await WalletUtil.provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [this.$config.chainInfo]
                    });
                    await WalletUtil.provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: this.$config.chainId }]
                    });
                    try {
                        WalletUtil.walletInit(() => { })
                    } catch (err) { }
                } catch (addError) {
                    console.warn(addError)
                }
            }
        },
        //获取市场列表
        async getInfo(){
            let contractReader = await ContractUtil.contractReader.at(this.blockchain.contractReader);
            contractReader.getAllMarket.call(this.addrs).then(res=>{
                // console.log(res,'getAllMarket');
                let p=Number(1+('0').repeat(18));
                this.addrs.forEach((item,index)=>{
                    let obj=this.dataList[index];
                    obj['oraclePrice']=Number(res[index][0])/p;//指数价格
                    obj['totalSize']=Number(res[index][2])/p*2;;//未平仓合约
                    this.$set(this.dataList,index,obj);
                });
                // console.log(this.dataList,'dataList')
            })
        },
        //当前账户
        getNowData(){
            //监听器在退出登录的时候有问题。
            // const equipment = new ContractUtil.web3.eth.Contract(ContractUtil.perpetual.abi,this.blockchain.perpetual);
            // // //取款
            // equipment.events.Withdraw({filter:{trader:this.addr}},(error,events)=>{
            //     console.log(events,'events')
            //     this.getData();
            // })
            // //存款
            // equipment.events.Deposit({filter:{trader:this.addr}},(error,events)=>{
            //     console.log(events,'events')
            //     this.getData();
            // })
            // // 清算
            // equipment.events.Liquidate({filter:{trader:this.addr}},(error,events)=>{
            //     console.log(events,'events')
            //     this.getData();
            // })
        },
        //获取账户余额
        async getData(){
            let res=await ContractUtil.getTraderPosition(this.blockchain.contractReader,this.blockchain.perpetual,this.addr)
            let availableMargin=res[5];
            let marginBalance=res[0];
            let p=Number(1+('0').repeat(18));
            if(!this.isInitWallet){
                this.marginBalance='';//保证金余额
                this.availableMargin='';//可用保证金
            }else{
                this.availableMargin= res?.walletMargin?.freeMargin ?? (Number(availableMargin)/p);
                this.marginBalance=Number(marginBalance)/p;
            }
        },
        // 未平仓合约
        async getOpenPositionContract(){
            let perpetualInstance=await ContractUtil.perpetual.at(this.blockchain.perpetual);
            let openPositionContract =(await perpetualInstance.totalSize(3)).toString();
            let p=Number(1+('0').repeat(18));
            let _openPositionContract=Number(openPositionContract)/p*2;
            //塞数据
            if(this.dataList.length>0){
                let list=this.dataList;
                list[0].openPositionContract=_openPositionContract;
                this.$set(this.dataList,0,list[0]);
            }
        },
        //获取预言机价格
        async chainlinkInstance(){
            let chainlinkInstance = await ContractUtil.amm.at(this.blockchain.fundmork);
            chainlinkInstance.indexPrice().then((res) => {
                let p=Number(1+('0').repeat(18));
                let chainlink=Number(res[0].toString())/p;
                //塞数据
                if(this.dataList.length>0){
                    let list=this.dataList;
                    list[0].chainlink=chainlink;
                    this.$set(this.dataList,0,list[0]);
                }
            });
        },
        //获取资金费率，8小时资金
        async getCapitalCost(){
            let instance=await ContractUtil.amm.at(this.blockchain.fundmork);
            let _capitalCost=(await instance.lastFundingRate.call()).toString();
            let p=Number(1+('0').repeat(18));
            let capitalCost=Number(_capitalCost)/p*100;
            //塞数据
            if(this.dataList.length>0){
                let list=this.dataList;
                list[0].capitalCost=capitalCost;
                this.$set(this.dataList,0,list[0]);
            }
        },
        //连接socket
        initSocket(){
            //盘口信息
            this.$eventBus.$on("handicapInformation_msg",(res)=>{
                // console.log("handicapInformation:" +  res);
                let list=res.data || [];
                if(this.dataList.length==0){
                    this.dataList=list;
                }else{
                    list=list.map((item,index)=>{
                        item={...this.dataList[index],...item};//追加不替换所有
                        return item;
                    });
                    this.dataList=list;
                }
                let addrs=[];
                this.dataList.forEach(item=>{
                    addrs.push(item.perpetual);
                });
                // let dataList=list;
                // let addrs=[];
                // dataList.forEach(item=>{
                //     addrs.push(item.perpetual);
                // });
                this.addrs=addrs;
                this.getInfo();
                timeIdInfo && clearInterval(timeIdInfo);
                timeIdInfo=setInterval(()=>{
                    this.getInfo();
                },5000);
            });
            sendMsg({cmd:'handicapInformation',data:this.blockchain.perpetual});
        },
        //表格触底
        scrollBottom(val){
            // if(val=='overview' && !this.fills.finished &&  !this.fills.loading){
            //     this.fills.current++;
            //     this.getAllOrder();
            // }
        },
    },
    computed: {
        shouldSwitchChain(){
            return this.isInitWallet && this.wallet.chainId!==parseInt(this.$config.chainId) && this.$store.state.app.walletActiveName!='Coinbase Wallet'
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
        //钱包地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
        //钱包
        wallet() {
            return this.$store.state.app.wallet;
        },
        //保证金使用
        marginBalanceUse(){
            if(this.marginBalance=='')return '';
            return this.availableMargin>=0 ? this.marginBalance-this.availableMargin : this.marginBalance;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
    },
    watch: {
        //监听多语言切换
        '$i18n.locale':function(){
            this.tableTitle=getOverviewTitleList();
        },
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    // timeId=setInterval(()=>{
                    //     this.getData();
                    // },5000);
                    // this.getData();
                }else{
                    this.marginBalance='';//保证金余额
                    this.availableMargin='';//可用保证金
                    clearInterval(timeId);
                }
            },
            deep: false,
            immediate: true
        },
        //切换交易对
        '$store.state.blockchain.perpetual':{
            handler:function(val,value){
                if(val){
                    this.getNowData();
                }
            },
            deep: false,
            immediate: false
        }
    },
    beforeDestroy() {
        this.$eventBus.$off("handicapInformation_msg");
        clearInterval(timeId);
        clearInterval(timeIdInfo);
    },
};
</script>

<style lang='less' scoped>
.tradeAssets{height:170px;border-bottom:1px solid @borderColor;.flexCenter;text-align:center;
    .tradeConnect{margin:0 30px;}
}
 .fontWeightTitle {font-weight: 700;}
 .borderB{border-bottom: 1px solid @borderColor;}
.portfolioOverview{flex:1;display:flex; flex-direction: column; overflow:auto;}
.POtop{display:flex;
    min-height:300px;
}
.POtopLeft{width:300px;height:100%;display: flex; flex-direction: column; justify-content: space-between;}
.POLtop{.p20; display: flex; flex:1 50%;flex-direction: column; justify-content: space-between;.borderB;border-right: 1px solid @borderColor;}
.POLtopTitle{flex:1;
    span{.op5;}
}
.POLtopMain{flex:1;display:flex; flex-direction: column; justify-content: center;
    >span{.f24;}
}
.POLTMcontent{
    >span:nth-of-type(1){color: @success-color;}
    >span:nth-of-type(2){.op5;.ml5;}
}
.POLmain{flex:1 50%;display: flex;flex-wrap: wrap;}
.POLmainQuarter{ padding: 10px 15px; flex:0 50%; .borderB;border-right: 1px solid @borderColor;
    .POLMQtitle{.pb10;.op5;}
}
.POtopRight{flex:1 300px;.flexCenter;height: 100%;.borderB;}
.POTRconnect{background:@graybg;border-radius:@border-radius-base ;.p20;max-width:300px;
    >div{.flexCenter;text-align:center}
}
.POMainTitle{.f24;.p20;.mt20;.fontWeightTitle;.borderB;}
.POMainContent{flex:1;overflow: hidden;}
.POMCshop{width:100%;
    >tr{display: flex;.borderB;padding: 0 20px;flex:1;width:100%;
        >tt{height: 40px;.op5; display: flex;align-items: center; .fontWeightTitle;
            &:hover{cursor: pointer; color:@success-color;}
        }
        >td{height: 50px;display: flex;align-items: center;
            >img{width:20px;height: 20px;.mr10;}
        }
    }
}
.POMCSCitemContent{.op5;}
</style>
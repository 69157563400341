<!--头寸-->
<template>
<div class='positions' style="position:relative;">
    
    <div class="tab-items">
        <div class="tab-btn" @click="e=>activeTab='stake'" :class="{active:activeTab=='stake'}" >{{$t('Staking')}}</div>
        <div class="tab-btn" @click="e=>activeTab='earn'" :class="{active:activeTab=='earn'}">{{$t('MyEarns')}}</div>
        <div class="tab-btn" @click="e=>activeTab='invite'" :class="{active:activeTab=='invite'}">{{$t('ReferralBonus')}}</div>
    </div>

    <div class="tab-content" v-if="activeTab=='stake'">
        <div class="panel">
            <div class="panel-title" style="color: #11E8F7;">{{$t('StakingMeke')}}</div>
            <div class="panel-desc">
                <div>{{$t('StakingMekeDesc')}}</div> 
            </div>
            <div class="card-row stake-items">
                <div class="stake-item" v-for="v in stakePeriods" :key="v.period">
                    <div class="stake-item-inner">
                        <div class="stake-item-info">
                            <div class="stake-item-period">{{v.period/3600/24}}{{$t('days')}}</div>
                            <div class="stake-item-apr">{{format(v.aprPPM/10000)}}%</div>
                            <div class="stake-item-apr-desc">{{$t('APR')}}</div>
                        </div>
                        <div class="btn-wrap">
                            <div class="btn" @click="e=>showStakeModal(v)">{{$t('StakeNow')}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel">
            <div class="panel-title" style="color: #991FF9;">{{$t('MekeSpecialPackage')}}</div>
            <div class="panel-desc">
                <div>{{$t('MekeSpecialPackageDesc')}}</div> 
            </div>
            <div class="card-row product-items">
                <div class="product-item" v-for="v,i in specials" :key="v.period">
                    <div class="product-item-inner">
                        <div class="corner-flag" v-if="i==0"><span class="corner-flag-content">{{$t('Special')}}</span></div>
                        <div class="product-item-period-info">
                            <div class="product-item-period">{{v.period/3600/24}}{{$t('days')}}</div>
                            <div style="display:flex;align-items:center;gap:8px">
                                <div class="product-item-apr-desc">{{$t('APR')}}</div>
                                <div class="product-item-apr">{{format(stakePeriods.find(s=>s.period===v.period).aprPPM/10000)}}%</div>
                            </div>
                        </div>
                        <div class="product-item-meke"> {{ format(v.payUsdt*1000000/v.discountPPM/commonData.lastTokenPrice)}}<span class="unit">MEKE</span></div>
                        <div class="product-item-info2">
                            <div class="product-item-discount">{{format(v.discountPPM/10000)}}%</div>
                            <div class="product-item-payPrice">{{format(v.payUsdt/1E18)}}<span class="unit">USDT</span></div>
                            <div class="product-item-origPrice">{{format(v.payUsdt/1E12/v.discountPPM)}} USDT</div>
                        </div>
                        <div class="product-item-desc">{{$t('BuyAndStakeDesc1')}}{{v.period/3600/24}}{{$t('BuyAndStakeDesc2')}}</div>
                        <div class="btn-wrap">
                            <div class="btn" @click="e=>buyAndStake({p:v, count:1})">{{$t('BuyItNow')}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel text-panel">
            <div class="panel-title" style="font-size:16px;">{{$t('MekeStakingRule')}}</div>
            <div class="panel-desc">
                <div>
                    {{$t('MekeStakingRuleDesc1')}}<br/>
                    {{$t('MekeStakingRuleDesc2')}}<br/>
                    {{$t('MekeStakingRuleDesc3')}}<br/>
                    {{$t('MekeStakingRuleDesc4')}}<br/>
                    {{$t('MekeStakingRuleDesc5')}}<br/>
                    {{$t('MekeStakingRuleDesc6')}}<br/>
                </div> 
            </div>
        </div>

        <div class="panel text-panel">
            <div class="panel-title" style="font-size:16px;">{{$t('StakingReleaseRule')}}</div>
            <div class="panel-desc">
                <div>
                    {{$t('StakingReleaseRuleDesc1')}}<br/>
                    {{$t('StakingReleaseRuleDesc2')}}<br/>
                </div> 
            </div>
        </div>

        <div class="panel text-panel">
            <div class="panel-title" style="font-size:16px;">{{$t('FeeDividendRule')}}</div>
            <div class="panel-desc">
                <div>
                    {{$t('FeeDividendRuleDesc1')}}<br/>
                    {{$t('FeeDividendRuleDesc2')}}<br/>
                    {{$t('FeeDividendRuleDesc3')}}<br/>
                </div> 
            </div>
        </div>
    </div>

    <div class="tab-content" v-if="activeTab=='earn'">
        <div class="panel">
            <div class="panel-title" style="color: #11E8F7;">{{$t('StakingEarns')}}</div>
            <div class="panel-desc">
                <div>{{$t('StakingMekeDesc')}}</div> 
            </div>
            <div class="card-row earn-items">
                <div class="earn-item">
                    <div class="earn-item-inner">
                        <div class="earn-item-info">
                            <div class="earn-item-label">{{$t('Principal')}}(MEKE)</div> 
                            <div class="earn-item-value">{{format(stakerData.stakerInfo?.principal/1E18)}}</div>
                        </div>
                        <div class="btn-wrap">
                            <div class="btn active" @click="e=>showStakeModal()">{{$t('StakeNow')}}</div>
                        </div>
                        <div style="display:flex;flex-direction:column;gap:8px;">
                            <div class="earn-item2">
                                <div class="earn-item-label">{{$t('TotalReleased')}}</div>
                                <div class="earn-item-value2">{{format4(stakerData.stakerInfo.released/1E18)}} <span>MEKE</span></div>
                            </div>
                            <div class="earn-item2">
                                <div class="earn-item-label">{{$t('MyShares')}}</div>
                                <div class="earn-item-value2">{{format(stakerData.stakerShares/1E16)}}%</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="earn-item">
                    <div class="earn-item-inner">
                        <div class="earn-item-info">
                            <div class="earn-item-label">{{$t('TotalInterest')}}(MEKE)</div> 
                            <div class="earn-item-value">{{format4(stakerData.stakerInfo?.interest/1E18)}}</div>
                        </div>
                        <div class="btn-wrap">
                            <div class="btn" @click="withdrawAllStake()">{{$t('WithdrawEarns')}}</div>
                        </div>
                        <div style="display:flex;flex-direction:column;gap:8px;">
                            <div class="earn-item2">
                                <div class="earn-item-label">{{$t('Available')}}</div>
                                <div class="earn-item-value2">{{format4((stakerData.stakerInfo.released - stakerData.stakerInfo.principalWithdrawn - stakerData.stakerInfo.interestWithdrawn)/1E18)}} <span>MEKE</span></div>
                            </div>
                            <div class="earn-item2">
                                <div class="earn-item-label">{{$t('Withdrawn')}}</div>
                                <div class="earn-item-value2">{{format4((stakerData.stakerInfo.principalWithdrawn + stakerData.stakerInfo.interestWithdrawn)/1E18)}} <span>MEKE</span></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="earn-item">
                    <div class="earn-item-inner">
                        <div class="earn-item-info">
                            <div class="earn-item-label">{{$t('TotalTransactionFee')}}(USDT)</div> 
                            <div class="earn-item-value">{{format4(stakerData.stakerFeeInfos?.[0]?.totalFee/1E18)}}</div>
                        </div>
                        <div class="btn-wrap">
                            <div class="btn" @click="e=>withdrawAllFee()">{{$t('WithdrawFees')}}</div>
                        </div>
                        <div style="display:flex;flex-direction:column;gap:8px;">
                            <div class="earn-item2">
                                <div class="earn-item-label">{{$t('Available')}}</div>
                                <div class="earn-item-value2">{{format4((stakerData.stakerFeeInfos?.[0]?.totalFee - stakerData.stakerFeeInfos?.[0]?.feeWithdrawn)/1E18)}} <span>USDT</span></div>
                            </div>
                            <div class="earn-item2">
                                <div class="earn-item-label">{{$t('Withdrawn')}}</div>
                                <div class="earn-item-value2">{{format4(stakerData.stakerFeeInfos?.[0]?.feeWithdrawn/1E18)}} <span>USDT</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="panel my-stakes">
            <div class="panel-title" style="color: #991FF9;">{{$t('StakingRecords')}}</div>
            <div style="background: #252525;padding:8px">
                <TradeTable 
                :columns="['C_StartTime','C_Period','C_APR','C_Principal','C_Interest','C_Released','C_Available'].map(t=>({title:$t(t)}))" 
                :tableData="stakerData.stakerInfo.records" :noData="$t('StakingRecords')" @scrollBottom="scrollBottom()">
                    <template v-slot:default="props">
                        <td style="white-space:nowrap;">{{utcDate(props.scope.startTime)}}</td>
                        <td>{{props.scope.period/3600/24}} {{$t('days')}}</td>
                        <td>{{format(props.scope.aprPPM/10000)}}%</td>
                        <td>{{format(props.scope.principal/1E18)}}</td>
                        <td>{{format4(props.scope.interest/1E18)}}</td>
                        <td>{{format(props.scope.released/(1*props.scope.principal + 1*props.scope.interest)*100)}}%</td>
                        <td>{{format4((props.scope.released - props.scope.principalWithdrawn - props.scope.interestWithdrawn)/1E18)}}</td>
                    </template>
                </TradeTable>
            </div>
        </div>

        <div class="panel my-extra">
            <div style="max-width:480px;background: #252525;padding:8px">
                <div class="panel-title" style="color: #f8f8f8; font-size:16px;padding:8px 20px;">{{$t('TransactionFeeExtraRewards')}}</div>
                <TradeTable 
                :columns="['D_Time','D_Amount'].map(t=>({title:$t(t)}))" 
                :tableData="extraRewards" :noData="$t('TransactionFeeExtraRewards')"  @scrollBottom="scrollBottom()">
                    <template v-slot:default="props">
                        <td>{{utcDate(props.scope.blockTime)}}</td>
                        <td>{{format(props.scope.stakerAmount/1E18)}}<span> USDT</span></td>
                    </template>
                </TradeTable>
            </div>
        </div>
    </div>

    <div class="tab-content" v-if="activeTab=='invite'">
        <div class="panel">
            <div class="panel-title" style="color: #B268FF;">{{$t('InviteFriends')}}</div>
            <div class="invite-banner active">
                <div class="invite-banner-inner" :style="{backgroundImage:`url(${inviteBannerBg})`}">
                    <div class="invite-banner-info" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="63" viewBox="0 0 75 63" fill="none">
                        <g filter="url(#filter0_d_1545_1410)">
                            <path d="M67.2888 1.47307C68.0024 1.04487 68.9281 1.27625 69.3563 1.98989L72.6514 7.4815C73.0796 8.19513 72.8482 9.12077 72.1346 9.54898L58.795 17.5532L57.1135 21.8571L51.5893 12.6507C51.1611 11.937 51.3925 11.0114 52.1061 10.5832L67.2888 1.47307Z" fill="#BAEF81"/>
                        </g>
                        <ellipse opacity="0.4" cx="17.9894" cy="17.6133" rx="17.9894" ry="17.6133" transform="matrix(0.768965 0.63929 -0.639291 0.768965 23.2291 11.5594)" fill="#991FF9"/>
                        <ellipse cx="17.9894" cy="17.6133" rx="17.9894" ry="17.6133" transform="matrix(0.768965 0.63929 -0.639291 0.768965 22.5201 12.36)" fill="#D7FF67"/>
                        <ellipse cx="17.9894" cy="17.6133" rx="17.9894" ry="17.6133" transform="matrix(0.768965 0.63929 -0.639291 0.768965 23.2291 11.5594)" fill="#991FF9"/>
                        <ellipse cx="15.7407" cy="15.4116" rx="15.7407" ry="15.4116" transform="matrix(0.768965 0.63929 -0.639291 0.768965 23.3412 14.707)" fill="#750DC8"/>
                        <g filter="url(#filter1_d_1545_1410)">
                            <path d="M32.6748 49.4238L32.808 44.2609C32.8123 44.1193 32.7042 44.0276 32.5676 44.0569L30.862 44.4281C30.7814 44.4452 30.7131 44.5291 30.7113 44.613L30.6225 47.9956C30.6214 48.0494 30.6535 48.1211 30.695 48.1562L32.3552 49.5644C32.5249 49.7084 32.6682 49.6437 32.6747 49.4219L32.6748 49.4238Z" fill="white"/>
                            <path d="M28.3679 37.6969C28.3172 37.7592 28.3132 37.8663 28.3586 37.9337L29.3582 39.4192C29.4392 39.5389 29.5799 39.5477 29.6691 39.4382L31.2071 37.5501C32.1312 36.4157 31.8786 35.6032 31.6462 35.2208C31.5789 35.1093 31.4796 34.9808 31.3389 34.8614C30.9942 34.5689 30.3967 34.3321 29.3852 34.5513L21.4032 36.2872L24.7101 32.2277C25.6751 31.043 25.4115 30.2211 25.1688 29.8367C24.926 29.4524 24.2979 28.865 22.8245 29.2445L12.2859 31.9549C12.086 32.0063 12.0537 32.1607 12.2145 32.2971L13.8196 33.6591C13.8618 33.6949 13.94 33.7137 13.9938 33.6998L22.5912 31.4902L19.3146 35.5125C18.3905 36.647 18.6421 37.4587 18.8745 37.8411C19.1071 38.2254 19.7102 38.8204 21.1355 38.5105L29.1185 36.7755L28.3679 37.6969Z" fill="white"/>
                            <path d="M38.5843 41.4244C38.7969 41.3774 38.8331 41.2218 38.6633 41.0777L37.0032 39.6697C36.9617 39.6345 36.8868 39.6154 36.8351 39.6263L27.6258 41.6304L28.3836 40.7005C28.4347 40.6378 28.4387 40.5301 28.393 40.4623L27.3876 38.9685C27.3061 38.8482 27.1655 38.8382 27.0749 38.9494L25.5239 40.8527C24.594 41.9938 24.8469 42.8101 25.0807 43.1947C25.2847 43.5318 25.7723 44.0308 26.8478 43.9448C27.0047 43.9326 27.1724 43.9073 27.3552 43.8675L37.6744 41.6219L38.5843 41.4244Z" fill="white"/>
                        </g>
                        <defs>
                            <filter id="filter0_d_1545_1410" x="51.3743" y="1.25809" width="22.2456" height="21.3525" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dx="0.753454" dy="0.753454"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.545098 0 0 0 0 0.203922 0 0 0 0 0.976471 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545_1410"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545_1410" result="shape"/>
                            </filter>
                            <filter id="filter1_d_1545_1410" x="12.1122" y="29.1219" width="26.6582" height="21.1413" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="0.621978"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.0823529 0 0 0 0 0.34902 0 0 0 0.2 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545_1410"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545_1410" result="shape"/>
                            </filter>
                        </defs>
                        </svg>
                        <div class="invite-banner-title">{{$t('InviteFriendsDesc')}}</div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="74" height="65" viewBox="0 0 74 65" fill="none">
                        <ellipse opacity="0.4" cx="57.7972" cy="16.4594" rx="11.5205" ry="11.2203" transform="rotate(30.6095 57.7972 16.4594)" fill="#991FF9"/>
                        <ellipse cx="57.4319" cy="17.0344" rx="11.5205" ry="11.2203" transform="rotate(30.6095 57.4319 17.0344)" fill="#D7FF67"/>
                        <ellipse cx="57.7972" cy="16.4594" rx="11.5205" ry="11.2203" transform="rotate(30.6095 57.7972 16.4594)" fill="#991FF9"/>
                        <ellipse cx="57.6664" cy="16.4909" rx="10.0804" ry="9.81774" transform="rotate(30.6095 57.6664 16.4909)" fill="#750DC8"/>
                        <g filter="url(#filter0_d_1545_1411)">
                            <path d="M63.4246 23.6881L62.9124 20.2291C62.8988 20.1342 62.8239 20.0822 62.7458 20.1135L61.7722 20.5072C61.7262 20.5255 61.6951 20.5874 61.7036 20.6436L62.0382 22.91C62.0437 22.946 62.0711 22.9911 62.0998 23.011L63.2503 23.8094C63.3679 23.891 63.4459 23.8355 63.4243 23.6869L63.4246 23.6881Z" fill="white"/>
                            <path d="M59.4356 16.275C59.4124 16.321 59.4223 16.3929 59.4572 16.4341L60.2244 17.3414C60.2865 17.4145 60.3715 17.4084 60.4122 17.3275L61.114 15.9333C61.5357 15.0956 61.2917 14.5741 61.1091 14.3384C61.0561 14.2696 60.9821 14.1923 60.8844 14.1245C60.645 13.9586 60.2611 13.8515 59.6823 14.0848L55.1153 15.9301L56.6242 12.9325C57.0646 12.0577 56.8129 11.5309 56.6239 11.2947C56.4349 11.0586 55.9925 10.7198 55.1562 11.0999L49.1745 13.816C49.061 13.8674 49.0594 13.9734 49.1711 14.0509L50.2857 14.8237C50.315 14.844 50.3639 14.8498 50.3944 14.836L55.2745 12.6212L53.7793 15.5914C53.3576 16.4291 53.6009 16.9501 53.7836 17.1858C53.9665 17.4228 54.3949 17.7688 55.2104 17.4394L59.7781 15.5946L59.4356 16.275Z" fill="white"/>
                            <path d="M66.0134 17.8666C66.1347 17.817 66.1384 17.71 66.0208 17.6284L64.8706 16.8302C64.8419 16.8103 64.795 16.8039 64.7655 16.8156L59.5093 18.9413L59.8542 18.2556C59.8774 18.2094 59.8675 18.1372 59.8325 18.0958L59.0625 17.1844C59.0002 17.111 58.9153 17.1164 58.874 17.1984L58.1682 18.6018C57.7449 19.4432 57.9891 19.9666 58.1724 20.2034C58.3326 20.411 58.6802 20.7025 59.311 20.5531C59.4031 20.5315 59.5001 20.5003 59.6044 20.4581L65.4941 18.0763L66.0134 17.8666Z" fill="white"/>
                        </g>
                        <g filter="url(#filter1_d_1545_1411)">
                            <path d="M14.706 22.6441C13.2758 21.8205 11.4488 22.3122 10.6252 23.7424L4.28728 34.748C3.46367 36.1781 3.95538 38.0052 5.38555 38.8288L32.119 54.2241L35.6063 62.6978L46.2315 44.2474C47.0551 42.8172 46.5634 40.9902 45.1332 40.1666L14.706 22.6441Z" fill="#FFD75A"/>
                        </g>
                        <defs>
                            <filter id="filter0_d_1545_1411" x="49.0884" y="10.9289" width="17.0184" height="13.3155" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="0.397269"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.0823529 0 0 0 0 0.34902 0 0 0 0.2 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545_1411"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545_1411" result="shape"/>
                            </filter>
                            <filter id="filter1_d_1545_1411" x="3.88806" y="22.2449" width="44.2368" height="41.947" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dx="1.49413" dy="1.49413"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.733333 0 0 0 0 0.941176 0 0 0 0 0.505882 0 0 0 1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545_1411"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545_1411" result="shape"/>
                            </filter>
                        </defs>
                        </svg>
                    </div>
                    <div class="invite-link-wrap">
                        <div class="invite-url">{{myShareLink}}</div>
                        <div class="btn active" @click="e=>copy(myShareLink)">{{$t('Copy')}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel text-panel">
            <div class="panel-title" style="font-size:16px;">{{$t('InvitationRule')}}</div>
            <div class="panel-desc">
                <div>
                    {{$t('InvitationRuleDesc1')}}<br/>
                    {{$t('InvitationRuleDesc2')}}<br/>
                    {{$t('InvitationRuleDesc3')}}<br/>
                </div> 
            </div>
        </div>

        <div class="panel text-panel">
            <div class="panel-title" style="padding:8px;font-size:16px; line-height:36px; display:flex; gap:16px;">
                <div style="color:#991FF9;">{{$t('TotalReferralBonus')}}</div>
                <div><span>{{format4(stakerData.referralInfo.totalBonus/1E18)}}</span> <span>MEKE</span></div>
                <Button class="btn" :disabled="(stakerData.referralInfo.totalBonus - stakerData.referralInfo.bonusWithdrawn)<=0" @click="e=>withdrawReferralBonus()">{{$t('WithdrawBonus')}}</Button>
                <div class="earn-item2">
                    <div class="earn-item-label">{{$t('Available')}}</div>
                    <div class="earn-item-value2">{{format4((stakerData.referralInfo.totalBonus - stakerData.referralInfo.bonusWithdrawn)/1E18)}} <span>MEKE</span></div>
                </div>
                <div class="earn-item2">
                    <div class="earn-item-label">{{$t('Withdrawn')}}</div>
                    <div class="earn-item-value2">{{format4(stakerData.referralInfo.bonusWithdrawn/1E18)}} <span>MEKE</span></div>
                </div>
            </div>
        </div>

        <div class="panel" style=" width:100%; flex-direction:row; gap:12px;">
            <div style="flex: 1; background: #252525;padding:8px">
                <div class="panel-title" style="color: #f8f8f8; font-size:16px;padding:8px 20px;">{{$t('InvitedFriends')}}</div>
                <TradeTable 
                :columns="['E_Addr','F_ActivationTime'].map(t=>({title:$t(t)}))" 
                :tableData="activeReferrees" :noData="$t('InvitedFriends')"  @scrollBottom="scrollBottom()">
                    <template v-slot:default="props">
                        <td>{{formatAddr(props.scope.referree)}}</td>
                        <td>{{utcDate(props.scope.blockTime)}}</td>
                    </template>
                </TradeTable>
            </div>

            <div style="flex:1; background: #252525;padding:8px">
                <div class="panel-title" style="color: #f8f8f8; font-size:16px;padding:8px 20px;">{{$t('ReferralBonus')}}</div>
                <TradeTable 
                :columns="['E_Addr','E_Bonus'].map(t=>({title:$t(t)}))" 
                :tableData="stakerData.referralInfo?.referrees" :noData="$t('ReferralBonus')"  @scrollBottom="scrollBottom()">
                    <template v-slot:default="props">
                        <td>{{formatAddr(props.scope.referree)}}</td>
                        <td><span>{{format4(props.scope.interest/1E18/10)}}</span><span style="margin-left:4px;opacity:0.65">MEKE</span></td>
                    </template>
                </TradeTable>
            </div>

        </div>
    </div>

    <ConnectWallet v-model="isconnectWallet" key="1"></ConnectWallet>
    <loading-layer v-model="isLayerPop"></loading-layer>
    <loading-pop v-model="isLoadingPop" text="Waiting..."></loading-pop>

    <Modal v-model="sp.show" width="400px" :mask-closable="false">
        <template slot="header">
        <Icon type="information-circled"></Icon>
        <span>{{ $t('Staking') }}</span>
        </template>
        <div class="form-v">
            <div class="form-item-v">
                <label class="form-item-label">{{ $t('Period') }}:</label>
                <Select :value="sp.p.period" @input="v=>{sp.p =stakePeriods.find(s=>s.period==v)}">
                    <Option v-for="v in stakePeriods" :value="v.period" :key="v.period">{{v.period/3600/24}} {{$t('days')}} {{$t('APR')}} {{v.aprPPM/10000}}%</Option>
                </Select>
            </div>

            <div class="form-item-v">
                <div class="flex-row">
                    <label class="form-item-label">{{ $t('Principal') }}(MEKE):</label>
                    <div><span class="field-label">{{ $t('Balance') }}</span><span class="field-value">{{ format(stakerData.tokenBalance/1E18) }}</span></div>
                </div>
                <InputNumber
                    style="display:block;width:100%"
                    :step="100"
                    :precision="0"
                    :min="1"
                    :max="ceil(stakerData.tokenBalance/1E18)"
                    v-model="sp.principal"
                />
                <div class="flex-row">
                    <span class="percent-btn" @click="sp.principal = floor(stakerData.tokenBalance/4/1E18)">25%</span>
                    <span class="percent-btn" @click="sp.principal = floor(stakerData.tokenBalance/2/1E18)">50%</span>
                    <span class="percent-btn" @click="sp.principal = floor(stakerData.tokenBalance*3/4/1E18)">75%</span>
                    <span class="percent-btn" @click="sp.principal = floor(stakerData.tokenBalance/1E18)">100%</span>
                </div>
            </div>

            <div class="form-item-v">
                <Checkbox v-model="sp.isCompound" > {{$t('CompoundInterest')}} </Checkbox>
            </div>

            <div class="flex-row">
                <div><span class="field-label">{{ $t('APR') }}</span><span class="field-value">{{ format(sp.p.aprPPM/10000) }}%</span></div>
                <div>
                    <span class="field-label">{{ $t('Interest') }}({{sp.p.period*2/3600/24}}{{$t('days')}})</span>
                    <span class="field-value">{{ format(calcSpInterest(sp)) }}</span>
                </div>
            </div>
        </div>

        <template slot="footer">
        <Button type="info" size="large" :disabled="sp.principal < 1" long @click="stake(sp)">
            <span>{{ $t('StakeNow') }}</span>
        </Button>
        </template>
    </Modal>
</div>
</template>

<script>
import ConnectWallet from "@/components/model/connectWallet.vue"
import {getEarnConfig,getActiveReferrees } from "@/api/http"
import {fromWei, toWei, calcSecondInterestRate} from "@/utils/signFn/constants"
import {clearStruct} from "@/utils/base.js"

import ContractUtil from "@/utils/contractUtil"
import WalletUtil from "@/utils/walletUtil"
import loadingPop from "@/components/loadingPop.vue"

import config from '@/config'

import TradeTable from "@/components/tradeTable.vue"
import inviteBannerBg from '@/assets/img/invite-banner-bg.svg'

const formatter= new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})

const formatter4= new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4
})

const formatter6= new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 6,
    maximumFractionDigits: 6
})

let pollTimeoutId = 0;
export default {
    name: '',
    props: {

    },
    components: {
        loadingPop, ConnectWallet,TradeTable
    },
    data() {
        return {
            activeTab:"stake",
            inviteBannerBg,
            myShareLink:'',
            loadingText:'',//加载中文字
            //表格标题
            isLayerPop:false,//加载中
            isLoadingPop:false,
            isconnectWallet:false,

            sp:{
                show:false,
                principal: 0,
                isCompound:true,
                p:{},
            },
            sb:{
                show:false,
                count: 1,
                isCompound:true,
                p:{},
            },

            // contract-data
            earnConfig:{},
            commonData:{
                lastTokenPrice:'20000000000000000'
            },
            stakerData:{
                stakerInfo:{},
                referralInfo:{},
                stakerFeeInfos:[],
                tokenBalance:'0',
                usdtBalance:'0',
            },
            stakePeriods: [
                {
                    period: "15552000",
                    aprPPM: "500000",
                },
                {
                    period: "7776000",
                    aprPPM: "350000",
                },
                
                {
                    period: "2592000",
                    aprPPM: "200000",
                },
                {
                    period: "432000",
                    aprPPM: "50000",
                },
            ],
            specials: [
                {
                    period: "15552000",
                    discountPPM: "850000",
                    payUsdt: "4000000000000000000000",
                },
                {
                    period: "7776000",
                    discountPPM: "900000",
                    payUsdt: "3000000000000000000000",
                },
                {
                    period: "2592000",
                    discountPPM: "950000",
                    payUsdt: "1500000000000000000000",
                },
                {
                    period: "432000",
                    discountPPM: "992000",
                    payUsdt: "500000000000000000000",
                }
            ],
            extraRewards:[],
            activeReferrees:[],
        }
    },
    mounted() {
        this.isLayerPop=false;
        let timeout=500;
        const poll=()=>{
            pollTimeoutId = setTimeout(async ()=>{
                if(ContractUtil.inititalized){
                    await this.loadEarnData().then(()=>{
                        this.isLayerPop=false;
                        timeout = 5000;
                    }).catch(err=>console.warn(err.message));
                }
                poll();
            },timeout);
        }
        poll();
    },
    beforeDestroy(){
        clearTimeout(pollTimeoutId);
    },

    methods: {
        log(msg){
            console.log(msg)
        },
        round(v){
            return Math.round(v);
        },
        ceil(v){
            return Math.ceil(v);
        },
        floor(v){
            return Math.floor(v);
        },
        calcSpInterest(sp){
            if(sp.principal <= 0 || !sp.p?.period){
                return 0;
            }
            if(sp.isCompound){
                return this.calcCompoundInterest(sp.p.period, sp.p.aprPPM, sp.principal, sp.p.period*2);
            }else{
                return this.calcSimpleInterest(sp.p.period, sp.p.aprPPM, sp.principal, sp.p.period*2);
            }
        },
        calcSimpleInterest(period, aprPPM, principal, duration){
            const secondInterestRate=Math.pow((Number(aprPPM)+1000000)/1000000, 1/3600/24/365)-1;
            return secondInterestRate * principal * Math.min(period*2,duration)
        },
        calcCompoundInterest(period, aprPPM, principal, duration){
            return principal * Math.pow((Number(aprPPM)+1000000)/1000000, Math.min(period*2,duration)/3600/24/365) - principal;
        },
        showBuySpecialModal(p){
            if(!this.checkChain()){
                return;
            }
            if(!p){
                p = this.specials[0];
            }
            this.sb={
                show:true,
                count: 1,
                isCompound:true,
                p,
            }
        },
        showStakeModal(p){
            if(!this.checkChain()){
                return;
            }
            if(!p){
                p = this.stakePeriods[0];
            }
            this.sp={
                show:true,
                principal: this.floor(this.stakerData.tokenBalance/1E18),
                isCompound:true,
                p,
            }
        },
        checkChain(){
            if(!this.addr){
                this.$Message.warning(this.$t('connectYourWallet'));
                this.isconnectWallet=true;
                return;
            }

            if(!this.earnConfig.earn){
                return;
            }

            if(this.chainId != parseInt(this.$config.chainInfo.chainId)){
                this.toggleChain(this.$config.chainInfo);
                return;
            }
            return true;
        },

        async buyAndStake(sb){
            if(!this.checkChain()){
                return;
            }
            try{
                this.isLoadingPop=true;
                const amount=BigInt(sb.count)*BigInt(sb.p.payUsdt);
                await this.tryApproveUsdt(amount);
                const earn=await WalletUtil.earn.at(this.earnConfig.earn);
                await earn.buyAndStake(sb.p.period, sb.count, true, {from:this.addr});
                this.$Message.success(this.$t('operationSucceeded'));
                this.loadEarnData().catch(err=>console.warn(err.message));
            }catch(err){
                console.warn(err.message);
                this.$Message.error(err.message);
            }finally {
                this.sb.show = false;
                this.isLoadingPop = false;
            }
        },
        async tryApproveUsdt(amount){
            const usdt=await ContractUtil.usdt.at(this.commonData.usdt);
            const allowance=await usdt.allowance(this.addr,this.earnConfig.earn).then(a=>BigInt(a.toString()));
            if(allowance < amount){
                const usdt2=await WalletUtil.usdt.at(this.commonData.usdt);
                await usdt2.approve(this.earnConfig.earn, amount,{from:this.addr});
            }
        },

        async stake(sp){
            if(!this.checkChain()){
                return;
            }
            try{
                this.isLoadingPop=true;
                const amount=BigInt(sp.principal)*BigInt(1E18)
                await this.tryApproveMeke(amount);
                const earn=await WalletUtil.earn.at(this.earnConfig.earn);
                await earn.stake(sp.p.period, amount, sp.isCompound, {from:this.addr});
                this.$Message.success(this.$t('operationSucceeded'));
                this.loadEarnData().catch(err=>console.warn(err.message));
            }catch(err){
                console.warn(err.message);
                this.$Message.error(err.message);
            }finally {
                this.sp.show = false;
                this.isLoadingPop = false;
            }
        },
        async tryApproveMeke(amount){
            const meke=await ContractUtil.meke.at(this.commonData.token);
            const allowance=await meke.allowance(this.addr,this.earnConfig.earn).then(a=>BigInt(a.toString()));
            if(allowance < amount){
                const meke2=await WalletUtil.meke.at(this.commonData.token);
                await meke2.approve(this.earnConfig.earn, amount,{from:this.addr});
            }
        },

        async withdrawAllStake(){
            if(!this.checkChain()){
                return;
            }
            try{
                this.isLoadingPop=true;
                const earn=await WalletUtil.earn.at(this.earnConfig.earn);
                await earn.withdrawAllStake({from:this.addr});
                this.$Message.success(this.$t('operationSucceeded'));
                this.loadEarnData().catch(err=>console.warn(err.message));
            }catch(err){
                console.warn(err.message);
                this.$Message.error(err.message);
            }finally {
                this.sp.show = false;
                this.isLoadingPop = false;
            }
        },
        async withdrawStake(id){
            if(!this.checkChain()){
                return;
            }
            try{
                this.isLoadingPop=true;
                const earn=await WalletUtil.earn.at(this.earnConfig.earn);
                await earn.withdrawStake(id,0,{from:this.addr});
                this.$Message.success(this.$t('operationSucceeded'));
                this.loadEarnData().catch(err=>console.warn(err.message));
            }catch(err){
                console.warn(err.message);
                this.$Message.error(err.message);
            }finally {
                this.sp.show = false;
                this.isLoadingPop = false;
            }
        },
        async withdrawAllFee(){
            if(!this.checkChain()){
                return;
            }
            try{
                this.isLoadingPop=true;
                const earn=await WalletUtil.earn.at(this.earnConfig.earn);
                await earn.withdrawAllFee({from:this.addr});
                this.$Message.success(this.$t('operationSucceeded'));
                this.loadEarnData().catch(err=>console.warn(err.message));
            }catch(err){
                console.warn(err.message);
                this.$Message.error(err.message);
            }finally {
                this.sp.show = false;
                this.isLoadingPop = false;
            }
        },
        async withdrawReferralBonus(){
            if(!this.checkChain()){
                return;
            }
            try{
                this.isLoadingPop=true;
                const earn=await WalletUtil.earn.at(this.earnConfig.earn);
                await earn.withdrawReferralBonus({from:this.addr});
                this.$Message.success(this.$t('operationSucceeded'));
                this.loadEarnData().catch(err=>console.warn(err.message));
            }catch(err){
                console.warn(err.message);
                this.$Message.error(err.message);
            }finally {
                this.sp.show = false;
                this.isLoadingPop = false;
            }
        },

        async loadEarnConfig(){
            if(!this.earnConfig.earn){
                this.earnConfig = await getEarnConfig(config.chainInfo.chainId);
            }
            return this.earnConfig;
        },
        async loadEarnData(){
            await this.loadEarnConfig();
            if(this.earnConfig.earn && this.earnConfig.earnReader){
                const earnReader=await ContractUtil.earnReader.at(this.earnConfig.earnReader);
                const {commonData, stakerData} = clearStruct(await earnReader.getEarnData(this.earnConfig.earn, this.addr || '0x0000000000000000000000000000000000000000'))
                stakerData.stakerInfo.records.sort((a,b)=>b.startTime-a.startTime);
                stakerData.referralInfo?.referrees.sort((a,b)=>Number(b.interest)-Number(a.interest));

                this.stakePeriods = commonData.stakePeriodInfos.sort((a,b)=>b.aprPPM - a.aprPPM);
                this.specials = commonData.specials.sort((a,b)=>a.discountPPM - b.discountPPM);
                this.commonData = commonData;
                this.stakerData = stakerData;
                if(this.addr){
                    this.activeReferrees = (await getActiveReferrees(this.addr).catch(err=>[])).sort((a,b)=>b.blockTime-a.blockTime);
                    this.loadExtraRewards(this.addr);
                }
            }
        },

        async loadExtraRewards(addr){
            const records = this.commonData?.perpetualFeeInfos?.[0]?.extraRewardsRecords??[];
            const extraRewards=[];
            if(records.length > 0 && this.stakerData.stakerInfo.records.length > 0){
                const earnReader=await ContractUtil.earnReader.at(this.earnConfig.earnReader);
                for(var i=0; i<records.length; i++){
                    const {blockNumber, blockTime, amount} = records[i];
                    const earn=this.earnConfig.earn;
                    const key=`stakerShares_${earn}_${addr}_at_${blockNumber}`;
                    let sharesAtBlock = localStorage.getItem(key);
                    if(sharesAtBlock == null && sessionStorage.getItem(key) != 'err' ){
                        sharesAtBlock=await earnReader.getStakerShares(earn,addr,blockNumber).catch(err=>{
                            console.warn(err);
                            sessionStorage.setItem(key,'err');
                            return null;
                        });
                        if(sharesAtBlock != null && sharesAtBlock != 'null'){
                            localStorage.setItem(key,sharesAtBlock.toString());
                        }
                    }
                    if(sharesAtBlock != null && sharesAtBlock != 'null'){
                        extraRewards.push({
                            blockTime,
                            blockNumber,
                            sharesAtBlock,
                            totalAmount: amount,
                            stakerAmount: sharesAtBlock.toString() * amount/1E18
                        })
                    }
                }
                this.extraRewards = extraRewards.sort((a,b)=>b.blockTime - a.blockTime);
            }
        },

        copy(txt) {
            this.$copyText(txt).then((e) => {
                this.$Message.success(this.$t('copySuccess'));
            }, function (e) {
            })
        },
        format(v){
            if(v===undefined || v===null || isNaN(v) || !Number.isFinite(v)){
                return '--'
            }
            return formatter.format(v)
        },
        format4(v){
            if(v===undefined || v===null || isNaN(v) || !Number.isFinite(v)){
                return '--'
            }
            return formatter4.format(v)
        },
        format6(v){
            if(v===undefined || v===null || isNaN(v) || !Number.isFinite(v)){
                return '--'
            }
            return formatter6.format(v)
        },
        formatAddr(addr){
            return addr.substring(0,8)+"*****"+addr.substring(addr.length - 6);
        },
        utcDate(timestamp, suffix=''){
            return new Date(timestamp*1000).toISOString().substring(0,16).replace('T',' ')+suffix;
        },

         //切换链
        async toggleChain(chainInfo) {
            const chain = chainInfo || this.$config.chainInfo
            try {
                await WalletUtil.provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chain.chainId }]
                });
                WalletUtil.walletInit(() => { })
            } catch (switchError) {
                console.warn(switchError)
                try {
                    await WalletUtil.provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [chain]
                    });
                    await WalletUtil.provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: chain.chainId }]
                    });
                    WalletUtil.walletInit(() => { })
                } catch (addError) {
                    console.warn(addError)
                }
            }
        }
    },
    computed: {
        //钱包地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
        //多语言切换
        lang(){
            return this.$i18n.locale;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
        chainId() {
            return this.$store.state.app.wallet.chainId;
        },
    },
    watch: {
        //监听多语言切换
        lang:function(){
        },
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    let url=document.URL;
                    this.myShareLink=url.split('?')[0]+`?addr=${this.addr}`;
                    this.loadEarnData();
                }
            },
            deep: false,
            immediate: true
        }
    },
};
</script>
<style lang='less' scoped>
.positions{
    flex:1;
    overflow:auto;
}

.tab-items {
    display: flex;
    gap:24px;
    border-bottom:1px solid #282828;
    padding-top:8px;
    .tab-btn {
        font-size:16px;
        cursor:pointer;
        padding:8px 24px;
        opacity:0.6;
        border-radius:4px;

        &:hover {
            color: #24AE8F;
        }

        &.active {
            opacity:1;
            background:black;
        }
    }
}

.tab-content {
    display:flex; 
    flex-direction: column; 
    padding:24px;
    gap:48px;
}

.panel {
    display:flex;
    flex-direction:column;
    gap:24px;
}

.panel-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #F5F5F5;
}
.panel-desc {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6F6E82;
}

.card-row {
    display:flex;
    gap:12px;
}

.btn {
    font-size:14px;
    line-height:20px;
    padding:8px 24px;
    border:none;
    border-radius:4px;
    color:white;
    white-space:nowrap;
    background:#303030;
    cursor:pointer;
    &.active {
        background: #991FF9;
    }
}

.colord-active {
    background: linear-gradient(117.61deg, #11E8F7 -1.35%, #991FF9 46.76%, #D7FF67 91.03%);
    .btn {
        background: #991FF9;
    }
}

.stake-items:not(:hover) .stake-item:first-child {
    .colord-active;
}

.stake-item {
    flex:1;
    display: block;
    padding: 1px;
    background: #252525;
    border-radius: 4px;
    &:hover, &.active {
        .colord-active;
    }

    &-inner {
        background: #252525;
        color: #E5E4E7;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content:center;

        border-radius: 4px;
        
        padding: 32px;
        gap: 32px;
    }
    &-info {
        display:flex;
        flex-direction:column;
        align-items: center;
        gap:8px;
    }
    &-period {
        font-size:16px;
        color:#F8F8F8;
    }
    &-apr {
        font-size:20px;
        color:#FC3B35;
    }
    &-apr-desc {
        font-size:12px;
        color: #898891;
    }
}

.unit {
    font-size:0.5em;
}

.product-items:not(:hover) .product-item:first-child {
    .colord-active;
}

.product-item {
    flex:1;
    display: block;
    padding: 1px;
    background: #252525;
    border-radius: 4px;
    &:hover, &.active {
        .colord-active;
    }

    &-inner {
        overflow:hidden;
        position:relative;
        background: #252525;
        color: #E5E4E7;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content:center;

        border-radius: 4px;
        
        padding: 32px;
        gap: 12px;

        .corner-flag {
            position:absolute;
            top:-30px;
            right:-40px;
            background: #991FF9;
            border-radius:60px;
            width: 100px;
            height: 60px;

            &-content {
                position:absolute;
                bottom:4px;
                left:22px;
            }
        }
    }

    &-period-info {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &-period {
        white-space:nowrap;
        font-size:16px;
        color:#F8F8F8;
    }
    &-apr {
        font-size:16px;
        color:#FC3B35;
        white-space:nowrap;
    }
    &-apr-desc {
        font-size:12px;
        color: #898891;
        white-space:nowrap;
    }

    &-meke {
        font-size:24px;
    }

    &-info2 {
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:4px;
    }

    &-discount {
        font-size:12px;
        color:#ffffff;
        padding: 2px 8px;
        background: #FC3B35;
        border-radius:4px;
        position:relative;

        &::after {
            content:"";
            background:#FC3B35;
            position:absolute;
            left:50%;
            margin-left: -4px;
            width:8px;
            height:8px;
            bottom:-4px;
            transform: rotate(45deg);
        }
    }

    &-payPrice {
        font-size:20px;
    }

    &-origPrice {
        font-size:16px;
        color: #898891;
        text-decoration: line-through;
    }

    &-desc {
        font-size:12px;
        color: #898891;
        margin-bottom:20px;
    }
}

.text-panel {
    background: #252525;
    padding:16px;
    gap:16px;
}


.earn-item {
    flex:1;
    display: block;
    padding: 1px;
    background: #252525;
    border-radius: 4px;
    &:hover, &.active {
        .colord-active;
    }

    &-inner {
        background: #252525;
        color: #E5E4E7;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content:center;

        border-radius: 4px;
        
        padding: 32px;
        gap: 32px;
    }
    &-info {
        display:flex;
        flex-direction:column;
        align-items: center;
        gap:8px;
    }
    &-value {
        font-size:24px;
        color:#F8F8F8;
    }
    &-value2 {
        font-size:14px;
    }
    &-label {
        font-size:14px;
        color: #898891;
    }
}

.earn-items2 {
    margin-top:-8px;
    padding:24px;
    display:flex;
    background:#252525;
    gap:12px;
    justify-content:space-between;
}

.earn-item2 {
    flex:1;
    display:flex;
    justify-content:center;
    gap: 8px;
}

.my-stakes .btn:hover {
    background: #991FF9;
}

.invite-banner {
    flex:1;
    display: block;
    padding: 1px;
    background: #252525;
    border-radius: 4px;
    &:hover, &.active {
        .colord-active;
    }

    &-inner {
        background: #252525;
        color: #E5E4E7;
        display: flex;
        flex-direction:column;
        align-items: center;
        justify-content:center;

        border-radius: 4px;
        
        padding: 32px;
        gap: 32px;
    }
    &-info {
        display:flex;
        align-items: center;
        gap:16px;
        padding: 20px 0;
    }

    &-title {font-size:36px;color:#E5E4E7;}
}




.invite-link-wrap {
    width:100%;
	display:flex;
    align-items:center;
	.btn {
		height:36px;
	}
}

.invite-url {
	flex:1;line-height:20px;padding:8px 16px;background:#111111;
    border-radius:4px;
}



.position-info {
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:4px;
    padding:8px;
    background:#181818;
    & > .flex-row > * {
        width: 50%;
    }
}

.form-item-v {
    display:flex;
    flex-direction: column;
    gap:4px;
}
.form-v {
    display:flex;
    flex-direction: column;
    gap:16px;
}

.field-label {
    font-size:0.9em;
    margin-right:8px;
    &:after {
        content:':';
    }
}
.field-value {
    font-size:0.9em;
    color:#888;
}

.percent-btn {
    display:inline-block;
    padding:4px 16px;
    border-radius: 4px;
    background:#1f1f1f;
    cursor:pointer;
}

.flex-grid {
    display:grid;
    gap:4px;
}

.flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
<template>
<div class="">
    <!--资产组合-视图：显示-->
    <div v-show="!isHidden" class="portfolioMainLeft">
      <div class="PMLmainTop">
        <div><span>{{this.$t("views")}}</span></div>
        <div class="PMLmainTopRight" @click="toggle()"><span>{{this.$t("hide")}}</span></div>
      </div>
      <ul class="PMLmainList">
        <li class="navSpan" v-for="(item,index) in navList" :key="index">
          <div @click="setNavActive(item)" :class="item.id===value.id?'active':''">
            <Icon :type="item.icon" />
            {{item.name}}
          </div>
        </li>
      </ul>
    </div>
    <!--隐藏-->
    <div v-show="isHidden" class="portfolioHidden flexCenter" @click="toggle()">
        <Icon type="ios-apps" size="24" />
    </div>
</div>
</template>

<script>
import {getNavList} from "../const/const.js"
export default {
  name: '',
  model:{
      prop:'value',
      event:'change'
  },
  props: {
      //活动菜单项
      value:{
          type:Object,
          default:{}
      }
  },
  components: {

  },
  data() {
    return {
        navList:[],//导航栏列表
        isHidden:JSON.parse(sessionStorage.getItem('portfolioIsHidden')) || false,//是否隐藏
    }
  },
  computed: {
        //多语言切换
        lang(){
            return this.$i18n.locale;
        }
  },
  watch: {
        //监听多语言切换
        lang:function(){
            this.init();
        }
  },
  methods: {
    //切换菜单显隐
    toggle(){
        this.isHidden=!this.isHidden;
        sessionStorage.setItem('portfolioIsHidden',JSON.stringify(this.isHidden));
    },
    //菜单初始化
    init(){
        this.navList = getNavList();
    },
    setNavActive(navActive){
      this.$emit("change",navActive);
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang='less' scoped>
.portfolioMainLeft {
    .p10;
  width: 320px;
  border-right: 1px solid @borderColor;
  display: flex;
  height:100%;
  flex-direction: column;
}
.PMLmainTop {
    .flexBetween;
    height: 40px;
    padding: 0 10px;
    span {
        opacity: 0.5;
    }
}
.PMLmainTopRight {
    cursor: pointer;
    padding: 1px 8px;
    border-radius: 8px;
    &:hover {
    background: #000;
    color: @success-color;
    }
}
.PMLmainList {
    .navSpan {
    width:100%;
    .mt10;
        .f16;
    position: relative;
    > div {
        > i {
        background:@graybg;border-radius: 50%;
        .p5;.mr10;
        }
        cursor: pointer;
        color: @color;
        padding: 10px 15px;
        height: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: @border-radius-base;
        &:hover {
        background: #000;
        color: @success-color;
        }
    }
    .active{
        background: #000;
        color: @success-color;
    }
    }
}

.portfolioHidden{
    width:56px;
    min-width:56px;
    border-right: 1px solid @borderColor;
    height:100%;
    &:hover{
        color: @success-color;
    }
}
</style>

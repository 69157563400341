<template>
  <div class="portfolioMain">
      <PortfolioMainLeft v-model="navActive" @change="changeNavActive"></PortfolioMainLeft><!--左部菜单-->
      <PortfolioOverview v-if="navActive.id===1"></PortfolioOverview><!--概述-->
      <PortfolioPositions v-if="navActive.id===2"></PortfolioPositions><!--头寸-->
      <PortfolioAirdrop2 v-if="navActive.id === 7"></PortfolioAirdrop2><!--空投-->
      <profit v-if="navActive.id===3"></profit>
      <!--奖励-->
      <tradeFee v-if="navActive.id===4"></tradeFee><!--交易费率-->
      <PorfolioOrders v-if="navActive.id===5"></PorfolioOrders><!--订单-->
      <!-- <PorfolioFees v-if="navActive.id===5"></PorfolioFees>fees -->
      <PorfolioHistory v-if="navActive.id===6"></PorfolioHistory><!--历史记录-->
      <PortfolioLiquidation v-if="navActive.id === 8"></PortfolioLiquidation>

      <Earn v-if="navActive.id === 11"></Earn>
  </div>
</template>
<script>
import PortfolioMainLeft from "./components/portfolioMainLeft.vue"
import PortfolioOverview from "./components/portfolioOverview"
import PortfolioPositions from "./components/portfolioPositions.vue"
import PortfolioAirdrop2 from "./components/portfolioAirdrop2.vue"
import PortfolioLiquidation from "./components/porfolioLiquidation.vue"
import profit from "./components/profit.vue"
import tradeFee from "./components/tradeFee.vue"
import PorfolioOrders from "./components/porfolioOrders.vue"
import PorfolioFees from "./components/porfolioFees.vue"
import PorfolioHistory from "./components/porfolioHistory.vue"

import Earn from "./components/earn.vue"
export default {
    name: '',
    props: {

    },
    components: {
        PortfolioMainLeft,
        PortfolioOverview,
        PortfolioPositions,
        PortfolioAirdrop2,
        profit,
        tradeFee,
        PorfolioOrders,
        PorfolioFees,
        PorfolioHistory,
        PortfolioLiquidation,
        Earn,
    },
    data() {
        const id= this.$route.query?.tab==='airdrop' ? 7 : 1;
        return {
            navActive:JSON.parse(sessionStorage.getItem("navActive"))||{id:id},
        }
    },
    methods: {
        //菜单项修改
        changeNavActive(val){
            sessionStorage.setItem("navActive",JSON.stringify(val));
        }
    },
    mounted() {
        
    },
    beforeDestroy() {
        //离开页面删除记录
        sessionStorage.removeItem("navActive");
        sessionStorage.removeItem("activeTitle");
    },
};
</script>
<style lang='less' scoped>
.portfolioMain {
  flex: 1;
  color: @color;
  display: flex;
  overflow: hidden;
}
/* H5的时候，隐藏滚动条 */
// ::-webkit-scrollbar {
// 	display: none;  
// 	width: 0 !important;  
// 	height: 0 !important;  
// 	-webkit-appearance: none;  
// 	background: transparent;  
// }
</style>
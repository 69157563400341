import { render, staticRenderFns } from "./porfolioOrders.vue?vue&type=template&id=2ccf4afa&scoped=true&"
import script from "./porfolioOrders.vue?vue&type=script&lang=js&"
export * from "./porfolioOrders.vue?vue&type=script&lang=js&"
import style0 from "./porfolioOrders.vue?vue&type=style&index=0&id=2ccf4afa&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_react-dom@16.14.0_react_27pp7puvtmqsmc3baxpxmlabky/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ccf4afa",
  null
  
)

export default component.exports
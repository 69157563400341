<!--资产组合-历史记录-->
<template>
<div class='history'>
    <!--最新成交，funding，转账-->
    <PortfolioTitle :titleList="titleList" v-model="activeTitle" @change="getActiveTitle" key="history"></PortfolioTitle>
    <div style="flex:1;overflow-y:auto;position:relative;">
        <!--最新成交-->
        <div class="pwh100" v-show="activeTitle.name===titleList[0].name">
            <trade-table :columns="tableTitleList.TradesTitle" :tableData="fillsDataList" :noData="$t('fills')+$t('fullStop')" :key="titleList[0].name" @scrollBottom="scrollBottom('fills')">
                <template v-slot:default="props">
                    <td>
                        <img :src="blockchain.img">
                        <div class="POMCSCitem">
                            <div><span style="white-space:nowrap;">{{blockchain.fullName}}</span></div>
                            <div class="POMCSCitemContent" style="white-space:nowrap;">{{blockchain.abbreviation}}-USD</div>
                        </div>
                    </td>
                    <td style="flex-direction:column;align-items:flex-start;">
                        <div style="white-space:nowrap;">{{props.scope.timeStr.substr(0,10)}}</div>
                        <div style="opacity: 0.5;">{{props.scope.timeStr.substr(11,8)}}</div>
                    </td>
                    <td>
                        <span v-if="props.scope.type=='market'">{{$t('market')}}</span>
                        <span v-else>{{$t('limit')}}</span>
                    </td>
                    <td>
                        <span v-if="props.scope.side=='LONG'" class="green">{{$t('buy')}}</span>
                        <span v-else class="red">{{$t('sell')}}</span>
                    </td>
                    <td :class="props.scope.side=='LONG'?'green':'red'">
                        <Icon v-if="props.scope.side=='LONG'" type="md-arrow-dropup"></Icon>
                        <Icon v-else type="md-arrow-dropdown" />
                        {{props.scope.volume | toFixed(3)}}
                    </td>
                    <td  style="white-space:nowrap;">
                        {{props.scope.price | numFormat(3)}}
                    </td>
                    <td><span style="white-space:nowrap;">{{props.scope.amount | numFormat(3)}}</span> / <span style="white-space:nowrap;">{{props.scope.fee*100/props.scope.amount | format(2,'%')}}</span></td>
                    <td>
                        <template v-if="props.scope.type=='LIMIT'">{{$t('maker')}}</template>
                        <template v-else>{{$t('taker')}}</template>
                    </td>
                    <td>
                        <span v-if="props.scope.status=='0'">{{$t('processing')}}</span>
                        <span v-if="props.scope.status=='1'">{{$t('successfulChaining')}}</span>
                        <span v-if="props.scope.status=='2'">{{$t('uplinkFailure')}}</span>
                    </td>
                </template>
            </trade-table>
            <loading-layer v-model="isLoadingLayer"></loading-layer>
        </div>
    </div>
</div>
</template>
<script>
import { getHistoryTitleList,getHistoryTitleTag } from '../const/const.js'
import { getAllOrder } from "@/api/http"
import PortfolioTitle from "./portfolioTitle.vue"
import tradeTable from "@/components/tradeTable.vue"
import config from "@/config";
import ContractUtil from "@/utils/contractUtil"
export default {
    props: {

    },
    components: {
        PortfolioTitle,
        tradeTable
    },
    data() {
        return {
            isLoadingLayer:true,//最新成交加载中
            isFundingLayer:true,//资金加载中
            //tabar标题
            activeTitle:JSON.parse(sessionStorage.getItem("activeTitle")) || {name:`${this.$t("trades")}`},
            titleList:getHistoryTitleTag(),
            //所有表格标题
            tableTitleList:getHistoryTitleList(),
            fillsDataList:[],//最新成交数据
            fundingList:[],//资金数据
            //最新成交表单
            fills:{
                loading:false,//加载中
                current:1,//当前页
                finished:false,//是否完成
                total:0,//总页
            },
        }
    },
    mounted() {
        this.init();
        // this.getFunding();
    },
    methods: {
        //获取资金
        async getFunding(){
            let addr=this.$store.state.app.wallet.address;
            //实例
            let perpetualInstance=await ContractUtil.perpetual.at(this.blockchain.perpetual);
            let contractReader = await ContractUtil.contractReader.deployed();
            let instance=await ContractUtil.amm.at(this.blockchain.fundmork);
            //执行合约方法
            let perpetualStorage=(await contractReader.getPerpetualStorage(this.blockchain.perpetual));
            let _capitalCost=(await instance.lastFundingRate.call()).toString();
            let _chainlink=(await instance.indexPrice.call())[0].toString();
            perpetualInstance.getMarginAccount(addr).then(res => {
                this.isFundingLayer=false;
                // console.log("res:",res);
                if(res[4].side!=2 && res[4].side!=3){    //没有持仓
                    this.fundingList=[];
                    return;
                }
                let p=Number(1+('0').repeat(18));
                let size=Number(res.size)/p;
                let accumulatedFundingPerContract=Number(perpetualStorage.fundingParams.accumulatedFundingPerContract)/p,
                    entryFundingLoss=Number(res.entryFundingLoss)/p,
                    longFundingLoss=Number(accumulatedFundingPerContract*size-entryFundingLoss),
                    capitalCost=Number(_capitalCost)/p*100,
                    chainlink=Number(_chainlink)/p;
                if(res.side==2){    //做空
                    longFundingLoss=-longFundingLoss;
                }
                //拼装数据
                let obj={};
                obj.time=this.fillsDataList[0].time;
                obj.timeStr = this.fillsDataList[0].timeStr;
                obj.price=this.fillsDataList[0].price;
                obj.longFundingLoss=longFundingLoss;
                obj.capitalCost=capitalCost;
                obj.size=size;
                obj.chainlink=chainlink;
                this.fundingList.push(obj);
            });
        },
        //表格触底
        scrollBottom(val){
            //最新成交
            if(val=='fills' && !this.fills.finished &&  !this.fills.loading){
                this.fills.current++;
                this.getAllOrder();
            }
        },
        //获取全部订单
        getAllOrder(){
            if(!this.addr){
                return;
            }

            let d={
                page:this.fills.current,
                size:15,
                perpetual: this.blockchain.perpetual
            };
            this.fills.loading=true;
            getAllOrder(this.addr, d).then(res=>{
                this.isLoadingLayer=false;
                this.fills.loading=false;
                if(res.code == 200){
                    this.fills.total=res.data.total || 0;
                    let list=res.data.records || [];
                    // if(d.current==1){
                    //     this.getFunding();
                    // }
                    if (list.length >= d.size) {
                        this.fills.finished = false;
                    } else {
                        this.fills.finished = true;
                    }
                    if(d.current==1){
                        this.fillsDataList=list;
                    }else{
                        this.fillsDataList=this.fillsDataList.concat(list);
                    }
                }else{
                    this.$Message.error(this.$t('operationFailed'));
                }
            })
            .catch(err=>{
                console.warn(err);
            });
        },
        //菜单活动项
        getActiveTitle(name){
            sessionStorage.setItem("activeTitle",JSON.stringify(name));
        },
        //数据初始化
        init(){
            this.activeTitle=JSON.parse(sessionStorage.getItem("activeTitle")) || {name:`${this.$t("trades")}`};
            this.tableTitleList=getHistoryTitleList();
            this.titleList=getHistoryTitleTag();
        },
    },
    computed: {
        //钱包地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
    },
    watch: {
        //监听多语言切换
        '$i18n.locale':function(){
            this.init();
        },
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    this.isLoadingLayer=true;
                    this.isFundingLayer=true;
                    this.fills.current=1;
                    this.getAllOrder();
                }else{
                    this.isLoadingLayer=false;
                    this.isFundingLayer=false;
                    this.fillsDataList=[];
                    this.fundingList=[];
                }
            },
            deep: false,
            immediate: true
        }
    },
    beforeDestroy() {
        sessionStorage.removeItem("activeTitle");
    },
};
</script>
<style lang='less' scoped>
  .history{flex:1;display:flex; flex-direction: column; overflow:auto;
}
</style>
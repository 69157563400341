<!--资产组合-交易费率-->
<template>
<div class='orders' style="position:relative;">
    <div class="ordersTitle">
        <span>{{$t("fee")}}</span>
    </div>
    <div class="content">
        <!--费率折扣-->
        <div class="feeRate">
            <div class="list">
                <div class="listTitle">{{$t('rateDiscount')}}</div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 16%;">{{$t('grade')}}</div>
                    <div class="td tdOther" style="flex-basis: 48%;">{{$t('currencyHolding')}}</div>
                    <div class="td tdOther" style="flex-basis: 36%;">{{$t('discount')}}</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 16%;">I</div>
                    <div class="td" style="flex-basis: 48%;">≥ 100</div>
                    <div class="td whiteColor" style="flex-basis: 36%;">3%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 16%;">II</div>
                    <div class="td" style="flex-basis: 48%;">≥ 1,000</div>
                    <div class="td whiteColor" style="flex-basis: 36%;">5%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 16%;">III</div>
                    <div class="td" style="flex-basis: 48%;">≥ 5,000</div>
                    <div class="td whiteColor" style="flex-basis: 36%;">10%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 16%;">IV</div>
                    <div class="td" style="flex-basis: 48%;">≥ 10,000</div>
                    <div class="td whiteColor" style="flex-basis: 36%;">15%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 16%;">V</div>
                    <div class="td" style="flex-basis: 48%;">≥ 50,000</div>
                    <div class="td whiteColor" style="flex-basis: 36%;">20%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 16%;">VI</div>
                    <div class="td" style="flex-basis: 48%;">≥ 100,000</div>
                    <div class="td whiteColor" style="flex-basis: 36%;">25%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 16%;">VII</div>
                    <div class="td" style="flex-basis: 48%;">≥ 200,000</div>
                    <div class="td whiteColor" style="flex-basis: 36%;">30%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 16%;">VIII</div>
                    <div class="td" style="flex-basis: 48%;">≥ 500,000</div>
                    <div class="td whiteColor" style="flex-basis: 36%;">35%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 16%;">IX</div>
                    <div class="td" style="flex-basis: 48%;">≥ 1,000,000</div>
                    <div class="td whiteColor" style="flex-basis: 36%;">40%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 16%;">X</div>
                    <div class="td" style="flex-basis: 48%;">≥ 2,500,000</div>
                    <div class="td whiteColor" style="flex-basis: 36%;">45%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne " style="flex-basis: 16%;">XI</div>
                    <div class="td" style="flex-basis: 48%;">≥ 5,000,000</div>
                    <div class="td whiteColor" style="flex-basis: 36%;">50%</div>
                </div>
            </div>
        </div>
        <!--费用结构-->
        <div class="feeConstructor">
            <div class="list">
                <div class="listTitle">{{$t('costStructure')}}</div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 10%;">{{$t('grade')}}</div>
                    <div class="td tdOther tdTwo" style="flex-basis: 40%;">{{$t('trade30')}}</div>
                    <div class="td tdOther" style="flex-basis: 25%;">{{$t('maker')}}</div>
                    <div class="td tdOther" style="flex-basis: 25%;">{{$t('taker')}}</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 10%;">I</div>
                    <div class="td tdTwo" style="flex-basis: 40%;">&lt; $1,000,000</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.050%</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.100%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 10%;">II</div>
                    <div class="td tdTwo" style="flex-basis: 40%;">≥ $1,000,000</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.040%</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.090%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 10%;">III</div>
                    <div class="td tdTwo" style="flex-basis: 40%;">≥ $5,000,000</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.030%</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.080%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 10%;">IV</div>
                    <div class="td tdTwo" style="flex-basis: 40%;">≥ $10,000,000</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.015%</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.075%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 10%;">V</div>
                    <div class="td tdTwo" style="flex-basis: 40%;">≥ $50,000,000</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.000%</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.070%</div>
                </div>
                <div class="listItem">
                    <div class="td tdOther tdOne" style="flex-basis: 10%;">VI</div>
                    <div class="td tdTwo" style="flex-basis: 40%;">≥ $200,000,000</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.000%</div>
                    <div class="td whiteColor" style="flex-basis: 25%;">0.060%</div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { getOrdersTitleList } from "../const/const.js";
import { getOrder, cancelOrder } from "@/api/http";
export default {
    name: "",
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
    computed: {},
    watch: {},
    beforeDestroy() {},
};
</script>
<style lang='less' scoped>
.orders {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.ordersTitle {
    .f24;
    .p20;
    font-weight: 700;
    border-bottom: 1px solid @borderColor;
}
.content {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 16px;
    padding:0 10px;
    
}
.feeRate {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(40% - 10px);
}
.list {
    max-height: -webkit-min-content;
    max-height: min-content;
    width: 100%;
    // background-color: @theme-color;
    background-color: @light-theme-color;
    border-radius: 12px;
    padding: 24px 0 16px;
}
.listTitle {
    font-size: 18px;
    line-height: 22px;
    color: @font-color;
    margin-bottom: 12px;
    padding: 0 24px;
}
.listItem {
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    height: 36px;
    width: 100%;
    padding: 0 24px;
}

.td.tdOne {
    justify-content: flex-start;
}
.td {
    font-family: Relative Mono;
    font-weight: 400;
    -webkit-font-feature-settings: "zero" 1;
    font-feature-settings: "zero" 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: @font-color;
    .op5;
    flex-grow: 1;
    flex-shrink: 0;
}
.td.tdOther {
    font-size: 16px;
    line-height: 20px;
    font-family: Relative Pro;
    font-weight: 500;
    -webkit-font-feature-settings: "zero" 0;
    font-feature-settings: "zero" 0;
}
.td.whiteColor {
    color:@font-color;
}
.content .feeConstructor {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(60% - 8px);
}
</style>
<!--资产组合-订单-->
<template>
<div class='orders' style="position:relative;">
    <div class="ordersTitle">
        <span>{{this.$t("Liquidation")}}</span>
    </div>
    <TradeTable :columns="['time','size','price','UsdAmount'].map(t=>({title:$t(t)}))" :tableData="liquidationDataList" :noData="$t('Liquidation')+$t('fullStop')" :key="`traderOrder`" @scrollBottom="scrollBottom()">
        <template v-slot:default="props">
            <td>{{ props.scope.timeStr }}</td>
            <td>{{ props.scope.volume | toFixed(blockchain.amountAccuracy)}} ETH</td>
            <td>${{ props.scope.price | toFixed(blockchain.amountAccuracy)}}</td>
            <td>${{ format(props.scope.amount)}}</td>
        </template>
    </TradeTable>
    <loading-layer v-model="isLoadingLayer"></loading-layer>
</div>
</template>
<script>
import TradeTable from "@/components/tradeTable.vue"
import config from "@/config";
import { getLiquidation } from "@/api/http"

const formatter= new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})

export default {
    name: '',
    props: {

    },
    components: {
            TradeTable,
    },
    data() {
        return {
            isLoadingLayer:true,
            liquidationDataList:[],//订单数据
        }
    },
    mounted() {
        
    },
    methods: {
        format(v){
            if(v===undefined || v===null || isNaN(v) || !Number.isFinite(v)){
                return '--'
            }
            return formatter.format(v)
        },
        scrollBottom(){

        },
        //获取订单（表格）
        getLiquidation(){
            if(!this.addr){
                this.isLoadingLayer=false;
                return;
            }

            let d={
                page:1,
                size:100,
            };
            getLiquidation(this.addr, d).then(res=>{
                this.isLoadingLayer=false;
                if(res.code == 200){
                    let list=(res.data.records || []).map(item=>{
                        item.timeStr=new Date(item.blockTime*1000).toISOString().substr(0,19).replace('T',' ');
                        return item;
                    });
                    this.liquidationDataList=list;
                }else{
                    this.$Message.error(this.$t('operationFailed'));
                }
            })
            .catch(err=>{
                console.warn(err);
            });
        },
    },
    computed: {
        //多语言切换
        lang(){
            return this.$i18n.locale;
        },
        //钱包地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
    },
    watch: {
        //监听多语言切换
        lang:function(){
            this.initTable();
        },
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    this.isLoadingLayer=true;
                    this.getLiquidation();
                }else{
                    this.isLoadingLayer=false;
                    this.liquidationDataList=[];
                }
            },
            deep: false,
            immediate: true
        }
    },
    beforeDestroy() {

    },
};
</script>
<style lang='less' scoped>
.orders{
    flex:1;display:flex; flex-direction: column; overflow:auto;
    .ordersTitle{.f24;.p20;font-weight: 700;border-bottom: 1px solid @borderColor;}
}
</style>
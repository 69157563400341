var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orders",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"ordersTitle"},[_c('span',[_vm._v(_vm._s(_vm.$t("fee")))])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"feeRate"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"listTitle"},[_vm._v(_vm._s(_vm.$t('rateDiscount')))]),_c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v(_vm._s(_vm.$t('grade')))]),_c('div',{staticClass:"td tdOther",staticStyle:{"flex-basis":"48%"}},[_vm._v(_vm._s(_vm.$t('currencyHolding')))]),_c('div',{staticClass:"td tdOther",staticStyle:{"flex-basis":"36%"}},[_vm._v(_vm._s(_vm.$t('discount')))])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])]),_c('div',{staticClass:"feeConstructor"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"listTitle"},[_vm._v(_vm._s(_vm.$t('costStructure')))]),_c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"10%"}},[_vm._v(_vm._s(_vm.$t('grade')))]),_c('div',{staticClass:"td tdOther tdTwo",staticStyle:{"flex-basis":"40%"}},[_vm._v(_vm._s(_vm.$t('trade30')))]),_c('div',{staticClass:"td tdOther",staticStyle:{"flex-basis":"25%"}},[_vm._v(_vm._s(_vm.$t('maker')))]),_c('div',{staticClass:"td tdOther",staticStyle:{"flex-basis":"25%"}},[_vm._v(_vm._s(_vm.$t('taker')))])]),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v("I")]),_c('div',{staticClass:"td",staticStyle:{"flex-basis":"48%"}},[_vm._v("≥ 100")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"36%"}},[_vm._v("3%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v("II")]),_c('div',{staticClass:"td",staticStyle:{"flex-basis":"48%"}},[_vm._v("≥ 1,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"36%"}},[_vm._v("5%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v("III")]),_c('div',{staticClass:"td",staticStyle:{"flex-basis":"48%"}},[_vm._v("≥ 5,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"36%"}},[_vm._v("10%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v("IV")]),_c('div',{staticClass:"td",staticStyle:{"flex-basis":"48%"}},[_vm._v("≥ 10,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"36%"}},[_vm._v("15%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v("V")]),_c('div',{staticClass:"td",staticStyle:{"flex-basis":"48%"}},[_vm._v("≥ 50,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"36%"}},[_vm._v("20%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v("VI")]),_c('div',{staticClass:"td",staticStyle:{"flex-basis":"48%"}},[_vm._v("≥ 100,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"36%"}},[_vm._v("25%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v("VII")]),_c('div',{staticClass:"td",staticStyle:{"flex-basis":"48%"}},[_vm._v("≥ 200,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"36%"}},[_vm._v("30%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v("VIII")]),_c('div',{staticClass:"td",staticStyle:{"flex-basis":"48%"}},[_vm._v("≥ 500,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"36%"}},[_vm._v("35%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v("IX")]),_c('div',{staticClass:"td",staticStyle:{"flex-basis":"48%"}},[_vm._v("≥ 1,000,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"36%"}},[_vm._v("40%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v("X")]),_c('div',{staticClass:"td",staticStyle:{"flex-basis":"48%"}},[_vm._v("≥ 2,500,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"36%"}},[_vm._v("45%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"16%"}},[_vm._v("XI")]),_c('div',{staticClass:"td",staticStyle:{"flex-basis":"48%"}},[_vm._v("≥ 5,000,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"36%"}},[_vm._v("50%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"10%"}},[_vm._v("I")]),_c('div',{staticClass:"td tdTwo",staticStyle:{"flex-basis":"40%"}},[_vm._v("< $1,000,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.050%")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.100%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"10%"}},[_vm._v("II")]),_c('div',{staticClass:"td tdTwo",staticStyle:{"flex-basis":"40%"}},[_vm._v("≥ $1,000,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.040%")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.090%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"10%"}},[_vm._v("III")]),_c('div',{staticClass:"td tdTwo",staticStyle:{"flex-basis":"40%"}},[_vm._v("≥ $5,000,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.030%")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.080%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"10%"}},[_vm._v("IV")]),_c('div',{staticClass:"td tdTwo",staticStyle:{"flex-basis":"40%"}},[_vm._v("≥ $10,000,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.015%")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.075%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"10%"}},[_vm._v("V")]),_c('div',{staticClass:"td tdTwo",staticStyle:{"flex-basis":"40%"}},[_vm._v("≥ $50,000,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.000%")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.070%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listItem"},[_c('div',{staticClass:"td tdOther tdOne",staticStyle:{"flex-basis":"10%"}},[_vm._v("VI")]),_c('div',{staticClass:"td tdTwo",staticStyle:{"flex-basis":"40%"}},[_vm._v("≥ $200,000,000")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.000%")]),_c('div',{staticClass:"td whiteColor",staticStyle:{"flex-basis":"25%"}},[_vm._v("0.060%")])])
}]

export { render, staticRenderFns }
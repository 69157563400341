<!--头寸-->
<template>
<div class='positions' style="position:relative;">
    <div class="panel">
        <div class="panel-title" style="color: #11E8F7;">{{ $t('Airdrop1') }}</div>
        <div class="card-row">
            <div class="value-card">
                <div class="value-card-label">{{ $t('CanGetUpToMEKE') }}</div>
                <div class="value-card-value">{{ format(airdropData.airdrop1.canGetupMeke) }}</div>
            </div>
            <div class="value-card">
                <div class="value-card-label">{{ $t('TradedUSDT') }}</div>
                <div class="value-card-value">{{ format(airdropData.airdrop1.tradedUsdt) }}</div>
            </div>
            <div class="value-card">
                <div class="value-card-label">{{ $t('EarnedMEKE') }}</div>
                <div class="value-card-value">{{ format(airdropData.airdrop1.earnedMeke) }}</div><!--Math.min(testUsdt, tradedUsdt)-->
            </div>
        </div>
    </div>

    <div class="panel">
        <div class="panel-title" style="color: #B268FF;">{{ $t('Airdrop2') }}</div>
        <div class="panel-desc">
            <div>{{ $t('airdrop2_desc1') }}</div> 
            <div>{{ $t('airdrop2_desc2') }}</div>
        </div>
        <div class="card-row">
            <div class="value-card">
                <div class="value-card-label">{{ $t('TradingPoints') }}</div>
                <div class="value-card-value">{{ format(airdropData.airdrop2.tradingPoints) }}</div>
            </div>
            <div class="value-card">
                <div class="value-card-label">{{ $t('InvitationPoints') }}</div>
                <div class="value-card-value">{{ format(airdropData.airdrop2.invitationPoints) }}</div>
            </div>
            <div class="value-card">
                <div class="value-card-label">{{ $t('ReferralPoints') }}</div>
                <div class="value-card-value">{{ format(airdropData.airdrop2.referralPoints) }}</div>
            </div>
            
            <div class="value-card">
                <div class="value-card-label">{{ $t('ReferralAdditionalRewards') }} MEKE</div>
                <div class="value-card-value-row">
                    <span class="value-card-value">{{ format(airdropData.airdrop2.referralAdditionalMeke) }}</span>
                    <button
                        v-if="0"
                        :disabled="airdropData.wmekeAirdrop.totalReleased - airdropData.wmekeAirdrop.referralAdditionalMekeCollected <=0.0001"
                        class="collect-btn" 
                        @click="collect(1)">{{ $t('collect') }}</button>
                </div>
                <div class="value-card-extra" v-if="0">
                    <span>{{ $t('Received') }}: {{ format(airdropData.wmekeAirdrop.referralAdditionalMekeCollected) }}</span>
                    <span>{{ $t('Remain') }}: {{ format(airdropData.wmekeAirdrop.totalReleased - airdropData.wmekeAirdrop.referralAdditionalMekeCollected) }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="panel">
        <div class="panel-title"><span>{{  $t('TotalAirdrop') }}</span> <router-link to="/Get_And_Swap_WMEKE" style="margin-left:5em;font-size:14px;color:#1989fa"><Icon type="ios-play-outline" /> {{ $t('WithdrawalTutorial') }}</router-link>
    </div>
        <div class="card-row">
            <div class="value-card" style="min-width:360px;height:152px">
                <div class="value-card-label">{{ $t('Airdrop1') }} + {{ $t('Airdrop2') }} MEKE</div>
                <div class="value-card-value-row" style="width:100%;">
                    <div class="value-card-value">{{ format(airdropData.totalAmount) }}</div>
                    <button
                        :disabled="airdropData.totalReleased - airdropData.totalAmountCollected<=0.0001"
                        class="collect-btn" 
                        @click="collect(2)">{{ $t('collect') }}</button>
                </div>
                <div class="value-card-extra" style="width:100%;">
                    <span>{{ $t('Received') }}: {{ format(airdropData.totalAmountCollected + airdropData.wmekeAirdrop.referralAdditionalMekeCollected) }}</span>  
                    <span>{{ $t('Remain') }}: {{ format(airdropData.totalReleased - airdropData.totalAmountCollected) }}</span>
                </div>
            </div>

            <div class="value-card" style="min-width:360px;height:152px;">
                <div class="value-card-label">{{ $t('Goto') }} uniswap</div>
                <a class="value-card-value" style="line-height:40px;color:#1989fa;" target="_blank" 
                        href="https://app.uniswap.org/#/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955&inputCurrency=0x3969BaFFB48240232a3dEFDE9D1420d2a40F38c9&chain=bnb"
                    >{{ $t('Swap') }} WMEKE</a>
                <div style="font-size:13px;margin-top:-8px;">
                    <div style="color:#6F6E82;margin-bottom:4px;">{{ $t('WMEKEAddress') }}</div>
                    <div style="cursor:pointer" @click="e => copy('0x3969BaFFB48240232a3dEFDE9D1420d2a40F38c9')">0x3969BaFFB48240232a3dEFDE9D1420d2a40F38c9</div>
                </div>
            </div>
        </div>
    </div>

    <ConnectWallet v-model="isconnectWallet" key="1"></ConnectWallet>
    <loading-layer v-model="isLayerPop"></loading-layer>
    <loading-pop v-model="isLoadingPop" text="Loading..."></loading-pop>
</div>
</template>

<script>
import ConnectWallet from "@/components/model/connectWallet.vue"
import { getAirdropDetail, getAirdropSign} from "@/api/http"
import {fromWei} from "@/utils/signFn/constants"
import ContractUtil from "@/utils/contractUtil"
import WalletUtil from "@/utils/walletUtil"
import loadingPop from "@/components/loadingPop.vue"

import config from '@/config'
import airdropAbi from '@/utils/build/contracts/Airdrop.json'

const formatter= new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})
const emptyData={
    airdrop1: { },
    airdrop2: { },
    wmekeAirdrop: {},
}

export default {
    name: '',
    props: {

    },
    components: {
        loadingPop, ConnectWallet
    },
    data() {
        return {
            loadingText:'',//加载中文字
            //表格标题
            isLayerPop:true,//加载中
            isLoadingPop:false,
            airdropData: emptyData,
            isconnectWallet:false,
        }
    },
    mounted() {
        this.getAirdropData()
    },
    methods: {
        copy(txt) {
            this.$copyText(txt).then((e) => {
                this.$Message.success(this.$t('copySuccess'));
            }, function (e) {
            })
        },
        format(v){
            if(v===undefined || v===null || isNaN(v)){
                return '--'
            }
            return formatter.format(v)
        },
        async getAirdropData() {
            const addr=this.addr;
            if(!addr){
                this.isLayerPop = false
                return
            }

            await getAirdropDetail(addr).then(res=>{
                res.data.wmekeAirdrop.referralAdditionalMekeCollected ||=0;
                res.data.totalAmountCollected ||=0;
                
                this.airdropData=res.data||emptyData;
            }).catch(err=>{
                this.airdropData=emptyData
                console.warn(err.message)
            }).finally(()=>{
                this.isLayerPop=false
            })

            this.updateData()
        },
        async updateData(){
            this.getAirdropCollected();
            this.getWmekeCollected();
        },

        getAirdrop(needChainId){
            const chainInfo=Object.values(config.chainInfos).find(c=>parseInt(c.chainId)===needChainId);
            if(chainInfo == null){
                throw new Error('Unsupport chain');
            }
            if(!chainInfo.provider){
                chainInfo.provider = ContractUtil.createProvider(chainInfo.rpcUrls);
            }

            if(!chainInfo.airdropContract){
                chainInfo.airdropContract = ContractUtil.createContract(airdropAbi, chainInfo.provider);
            }
            return chainInfo.airdropContract;
        },

        async getWmekeCollected(){
            const record = this.airdropData.wmekeAirdrop;
            const airdrop = await this.getAirdrop(parseInt(record.chainId)).at(record.contract)
            const collectedAmount = await airdrop.collectedAmounts(record.airdropId)
            const collected =  Number(fromWei(collectedAmount));
            this.airdropData.wmekeAirdrop.referralAdditionalMekeCollected = collected
        },

        async getAirdropCollected(){
            const record = this.airdropData;
            const airdrop=await this.getAirdrop(parseInt(this.airdropData.chainId)).at(record.contract)
            const collectedAmount = await airdrop.collectedAmounts(record.airdropId)
            const collected =  Number(fromWei(collectedAmount));
            record.totalAmountCollected = collected;
        },

        async collect(activityId){
            let addr=this.addr;
            if (!this.addr) {
                this.isconnectWallet = true
                return;
            }
            let needChainId = parseInt(this.airdropData.chainId);
            if(activityId===1){
                needChainId=parseInt(this.airdropData.wmekeAirdrop.chainId);
            }
            if(this.chainId !== needChainId ){
                const chainInfo=Object.values(config.chainInfos).find(c=>parseInt(c.chainId)===needChainId);
                this.toggleChain(chainInfo);
                return
            }

            this.isLoadingPop = true
            try{
                const res=await getAirdropSign(addr, activityId)
                const {contract, signature, rawData:{timestamp,airdropId,totalReleased}={}} = res.data||{}
                if(signature && contract){
                    const airdrop=await WalletUtil.airdrop.at(contract)
                    const tx=await airdrop.collect(airdropId,BigInt(totalReleased),timestamp,signature,{from: addr})
                    this.updateData(false);
                    this.$Message.success(this.$t('SuccessToCollect'))
                }
            }catch(err){
                let errMsg = err.message || 'Error';
                console.warn(errMsg);
                const dataStart = errMsg.indexOf('{')
                const dataEnd = errMsg.lastIndexOf('}');
                if (dataStart > 0 && dataEnd > 0) {
                    try {
                        const data = JSON.parse(errMsg.substring(dataStart, dataEnd + 1));
                        errMsg = data.message || errMsg;
                    } catch (e) {
                        //ignore;
                    }
                }
                if (errMsg.toLowerCase().includes('signature')) {
                    errMsg = this.$t("InvalidSignature");
                } else if (errMsg.includes('Insufficient balance')) {
                    errMsg = this.$t("AirdropInsufficient");
                }
                this.$Message.error(this.$t('operationFailed') + '<br/>' + errMsg);
            }
            this.isLoadingPop = false
        },
         //切换链
        async toggleChain(chainInfo) {
            const chain = chainInfo || config.chainInfo
            try {
                await WalletUtil.provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: chain.chainId }]
                });
                WalletUtil.walletInit(() => { })
            } catch (switchError) {
                console.warn(switchError)
                try {
                    await WalletUtil.provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [chain]
                    });
                    await WalletUtil.provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: chain.chainId }]
                    });
                    WalletUtil.walletInit(() => { })
                } catch (addError) {
                    console.warn(addError)
                }
            }
        }
    },
    computed: {
        //钱包地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
        //多语言切换
        lang(){
            return this.$i18n.locale;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
        chainId() {
            return this.$store.state.app.wallet.chainId;
        },
    },
    watch: {
        //监听多语言切换
        lang:function(){
        },
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    this.getAirdropData()
                }else{
                    this.airdropData= emptyData
                }
            },
            deep: false,
            immediate: true
        }
    },
};
</script>
<style lang='less' scoped>
.positions{
    flex:1;
    display:flex; 
    flex-direction: column; 
    overflow:auto;
    padding:24px;
    gap:48px;
}

.panel {
    display:flex;
    flex-direction:column;
    gap:24px;
}

.panel-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #F5F5F5;
}
.panel-desc {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6F6E82;
}

.card-row {
    display:flex;
    gap:12px;
}

.value-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 24px 32px;
    gap: 12px;

    width: 264px;
    height: 127px;

    border: 1px solid #333333;
    border-radius: 4px;
}

.value-card-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #6F6E82;
}

.value-card-value-row {
    gap:24px;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.value-card-value {
    font-weight: 600;
    font-size: 28px;
    line-height: 39px;
    text-align: center;

    color: #F5F5F5;
}

.collect-btn {
    height:28px;
    font-size:14px;
    line-height:18px;
    padding:4px 8px;
    border:1px solid #ddd;
    border-radius:4px;
    background:transparent;
    color:white;
    white-space:nowrap;
}

.collect-btn:disabled {
    opacity:0.65;
    cursor:default;
}
.value-card-extra {
    gap:24px;
    display:flex;
    justify-content: space-between; 
    opacity:0.8;
    font-size:12px;
    margin-top:-8px;
    & > span {
        white-space: nowrap;
    }
}
</style>
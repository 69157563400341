<!--资产组合-订单-->
<template>
  <div class='fees'>
     <div class="feesTitle">
         <span>Fees</span>
     </div>
      <div class="feesMain">
          <div class="feesMainLeft">
            <div class="FMLtop">
                <div class="FMLtopTOP">
                    <div class="FMLitem">
                        <div class="FMLitemTitle">Fees(30d)</div>
                        <div>999</div>
                    </div>
                </div>
                <div class="FMLtopBottom">
                    <div class="FMLitem">
                        <div class="FMLitemTitle">{{this.$t("maker")}}{{this.$t("fee")}}</div>
                        <div>-</div>
                    </div>
                    <div class="FMLitem">
                        <div class="FMLitemTitle">{{this.$t("taker")}}{{this.$t("fee")}}</div>
                        <div>-</div>
                    </div>
                </div>
            </div>
            <div class="FMLcontent">
                <div class="FMLcontentTitle">Trailing Volume</div>
                <div class="FMLcontentContent">
                    <div class="f20">-over</div>
                    <div class="op5">over the last 30 days</div>
                </div>
            </div>
          </div>
          <div class="feesMainRight">
              <div class="FMRtitle">
                  Fees Structure
              </div>
              <div class="FMRtable">
                  <tr class="FMRtableTitle">
                      <tt>Tier</tt>
                      <tt>Volume(30d)</tt>
                      <tt>{{this.$t("maker")}}</tt>
                      <tt>{{this.$t("taker")}}</tt>
                  </tr>
                  <tr class="FMRtableContent" v-for="(item,index) in tableData" :key="index">
                      <td>{{item.tier}}</td>
                      <td>
                        <div v-if="index===0" v-text="`<`" class="mr10 flexCenter"></div>
                        <div v-else v-text="`≥`" class="mr10 flexCenter"></div>
                        ${{item.volume}}
                      </td>
                      <td>{{item.register}}</td>
                      <td>{{item.takebill}}</td>
                  </tr>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import PortfolioTitle from "./portfolioTitle.vue"
export default {
  name: '',
  props: {

   },
  components: {
PortfolioTitle
   },
  data() {
    return {
        tableData:[
            {
                tier:"I",
                volume:"1,000,000",
                register:"0.050%",
                takebill:"0.200%",
            },
            {
                tier:"II",
                volume:"1,000,000",
                register:"0.050%",
                takebill:"0.200%",
            },
            {
                tier:"III",
                volume:"5,000,000",
                register:"0.050%",
                takebill:"0.200%",
            },
            {
                tier:"IV",
                volume:"10,000,000",
                register:"0.050%",
                takebill:"0.200%",
            },
            {
                tier:"V",
                volume:"50,000,000",
                register:"0.050%",
                takebill:"0.200%",
            },
        ]
   }
   },
  computed: {

   },
  watch: {

   },
  methods: {

   },
  created() {

   },
  mounted() {

   },
  beforeDestroy() {

   },
};
</script>
<style lang='less' scoped>
.fees{
    flex:1;display:flex; flex-direction: column; overflow:auto;
    .feesTitle{.f24;.p20;font-weight: 700;border-bottom: 1px solid @borderColor;}
}
.feesMain{display: flex;.p20;height:450px;
    .feesMainLeft{width:300px;display:flex;flex-direction: column;
        .FMLtop{border-radius:@border-radius-base;background:#33333377;flex:1;
            .FMLtopTOP{border-bottom: 1px solid @borderColor;}
            .FMLitemTitle{.pb10;.op5;}
            .FMLtopBottom{display: flex;flex:1;width:100%;
                .FMLitem:nth-of-type(1){ border-right: 1px solid @borderColor;
                }
            }
        }
        .FMLcontent{border-radius:@border-radius-base;background:#33333377;.p20;.mt15;flex:1;display:flex;flex-direction:column; justify-content: space-between;
            .FMLcontentTitle{.f18;font-weight: 700;}
        }
        .FMLitem{width:100%; .p20;}
    }
    .feesMainRight{flex :1 300px;.ml20;.p20;border-radius:@border-radius-base;background:@graybg;
        .FMRtitle{.f20;}
        .FMRtable{.pt10;.pb20;display:flex; flex-direction: column;height: calc(100% - 10px);
            .FMRtableTitle{display: flex;
                >tt{flex: 1;.f16;.op5;font-weight: 700;}
            }
            .FMRtableContent{display: flex;flex:1;
                >td{flex: 1;.op5;.f16;display:flex;align-items:center;
                    >span{text-align: center;}
                }
            }
        }
        
    }
}
</style>

//概述
export function getOverviewTitleList(){
    return [
        {title:`${_vm.$t("market")}`},
        {title:`${_vm.$t("oraclePrice")}`},
        {title:`${_vm.$t("change24h")}`},
        {title:`${_vm.$t("funding8h")}`},
        {title:`${_vm.$t("openInterest")}`},
        {title:`${_vm.$t("volume24h")}`},
        {title:`${_vm.$t("amount24h")}`},
    ];
}


//资产组合-视图菜单列表
export function getNavList(){
    const navList = [
      {
        name: `${_vm.$t('overview')}`,
        id: 1,
        icon: 'md-cloudy'
      },
      {
        name: `${_vm.$t('positions')}`,
        icon: 'ios-cube',
        id: 2
      },
      {
        name: `${_vm.$t('mining')}`,
        icon: 'md-bowtie',
        id: 3
      },
      {
        name: `${_vm.$t('airdrop')}`,
        icon: 'ios-plane',
        id: 7
      },
      {
        name: `${_vm.$t('fee')}`,
        icon: 'md-calculator',
        id: 4
      },
      {
        name: `${_vm.$t('orders')}`,
        icon: 'md-reorder',
        id: 5
      },
      // {
      //   name:"Fees",
      //   icon:"ios-calculator",
      //   id:4,
      // },
      {
        name: `${_vm.$t('history')}`,
        icon: 'ios-time',
        id: 6
      },
      {
        name: `${_vm.$t('Liquidation')}`,
        icon: 'ios-bonfire-outline',
        id: 8
      },

      {
        name: `${_vm.$t('Earn')}`,
        icon: 'ios-bonfire-outline',
        id: 11
      }
    ]
    return navList;
}

//资产组合-头寸
export function getTableTitleList(){
    const tableTitleList=[
        {title:`${_vm.$t('contract')}`},
        {title:`${_vm.$t('direction')}`},
        {title:`${_vm.$t('amount')}`,
            // tag:true,
        },
        {title:_vm.$t('margin')},
        {title:_vm.$t('marginRate')},
        {title:_vm.$t('positionPrice')},
        {title:_vm.$t('signPrice')},
        {title:_vm.$t('strongPrice')},
        // {title:_vm.$t('serviceCharge')},
        {title:_vm.$t('profitLoss')},
        {title:_vm.$t('operation')},
    ];
    return tableTitleList;
}

//历史记录-三个表格
export function getHistoryTitleList(){
    const list={
        //最新交易数据标题
        TradesTitle:[
            {title:`${_vm.$t("systemStatus")}`},
            {title:`${_vm.$t("time")}`},
            {title:`${_vm.$t("type")}`},
            {title:`${_vm.$t("side")}`},
            {title:`${_vm.$t("amount")}`,
                // tag:true,
            },
            {title:`${_vm.$t("price")}`},
            {title:`${_vm.$t("totalFee")}`,},
            {
                title:`${_vm.$t("liquidity")}`,
                message:{title:`${_vm.$t("liquidityTitle")}`,content:`${_vm.$t("liquidityIntroduce")}`,placement:'bottom-end'}
                
            },
            {title:`${_vm.$t('status')}`}
        ],
        //funding数据标题
        FundingTitle:[
            {title:`${_vm.$t("time")}`},
            {title:`${_vm.$t("market")}`},
            {title:`${_vm.$t('payment')}`},
            {title:`${_vm.$t("fundingRate")}`},
            {title:`${_vm.$t("position")}`},
            {title:`${_vm.$t("oraclePrice")}`},
        ],
        //转账数据标题
        // TransfersTitle:[
        //     {title:`${_vm.$t("time")}`},
        //     {title:`${_vm.$t("action")}`},
        //     {title:`${_vm.$t("systemStatus")}`},
        //     {title:`${_vm.$t("amountFee")}`},
        //     {title:`${_vm.$t("transaction")}`},
        // ]
    };
    return list;
}

//资产组合-订单 ：代表着标题行右边的币种
export function getOrdersTitleList(){
    return [
        {title:`${_vm.$t("systemStatus")}`},
        {title:`${_vm.$t("side")}`},
        {title:`${_vm.$t("amountFilled")}`},
        {title:`${_vm.$t("price")}`},
        // {title:`${_vm.$t("stopPrice")}`,
        //     message:{title:`${_vm.$t("stopPriceTitle")}`,content:`${_vm.$t("stopPriceIntroduce")}`,placement:'bottom-end'}
        // },
        {title:`${_vm.$t("goodTil")}`,
            message:{title:`${_vm.$t("goodTil")}`,content:`${_vm.$t("goodTilIntroduce")}`,placement:'bottom-end'}
        },
        {title:``},
    ];
}

export function getLiquidationTitleList(){
    return [
      { title: `${_vm.$t('side')}` },      
      { title: `${_vm.$t('price')}` },
      { title: `${_vm.$t('amount')}` },
      { title: `${_vm.$t('time')}` },
    ]
}

//挖矿标题
export function getProfitTitleList(){
    return [
        {title:`${_vm.$t("type")}`},
        {title:`${_vm.$t("amount")}`},
        {title:`${_vm.$t("status")}`},
        {title:`${_vm.$t("time")}`},
    ];
}


//历史记录标题
export function getHistoryTitleTag(){
    return [
        {name:`${_vm.$t("trades")}`},
        // {name:`${_vm.$t('funding')}`},
        // {name:`${_vm.$t("transfers")}`}
    ];
}


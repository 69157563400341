<!--资产组合-菜单-->
<template>
  <div class='portfolioTitle'>
      <div class="contentTitle" v-for="(item,index) in titleList" :key="index" :class="active.name===item.name?'':'op5'" @click="upActive(item)">{{item.name}}</div>
  </div>
</template>
<script>
export default {
  name: '',
  model:{
      prop:'active',
      event:'change'
  },
  props: {
      //标题列表
      titleList:{
          type:Array,
          default:()=>{
              return [];
          }
      },
      //活动项
      active:{
        type:Object,
        default: function() {
          return this.titleList[0];
        }
      },
   },
  components: {

   },
  data() {
    return {
   }
   },
  computed: {

   },
  watch: {

   },
  methods: {
      //右侧头部标题
      upActive(val){
        this.$emit("change",val);
      }
   },
  created() {

   },
  mounted() {

   },
  beforeDestroy() {

   },
};
</script>
<style lang='less' scoped>
 .fontWeightTitle {font-weight: 700;}
 .borderB{border-bottom: 1px solid @borderColor;}

.portfolioTitle{.borderB;display:flex;width:100%;
.contentTitle{.f24;.p20;.fontWeightTitle;
  &:hover{cursor: pointer;}
}
}
</style>